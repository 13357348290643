<template>
    <b-row style="font-size: 90%">
        <GlobalEvents
            @keyup.113="openManualAddItemModal"/>
        <b-col class="px-2" cols="12" style="height: 50px">
            <SaleNavbarComponent :sale-tab="SaleTab" @resetNewTab="resetNewTab"/>
        </b-col>
        <b-col class="firstBlock px-2" lg="8" xl="9">
            <b-card class="soft-primary mt-2"
                    body-class="p-0 d-xl-flex align-items-xl-center">
                <div class="w-100 px-3 d-xl-flex align-items-xl-center justify-content-xl-between">
                    <div class="d-flex align-items-center px-2 my-2 my-lg-0">
                        <small class="mr-2">Склад:</small>
                        <WarehouseSearchElement/>
                    </div>
                    <div class="d-flex align-items-center px-2 my-2 my-lg-0">
                        <small class="mr-2">Клиент:</small>
                        <ClientSearchElement v-model="activeTab.Sale.Client"/>
                    </div>
                    <div class="text-right my-2 my-lg-0">
                        <b-button size="sm" class="rounded-2 border-0" variant="info" @click="openManualAddItemModal">
                            Добавить товар вручную
                            <b-icon-plus class="cursor-pointer" font-scale="1.2"
                                         variant="black"></b-icon-plus>
                        </b-button>
                    </div>
                </div>
            </b-card>
            <b-card footer-class="p-0 d-flex bg-white"
                    class="shadow"
                    body-class="p-0 m-0"
                    style="height:calc(98vh - 100px); margin-top: 10px">
                <SaleBasketListComponent :Sale="activeTab.Sale" :Items="activeTab.Items" ref="SaleBasketListComponent"
                                         @reloadSale="loadActiveTabSaleRegister"/>
            </b-card>
        </b-col>
        <b-col class="px-2" lg="4" xl="3">
            <SaleClientPaymentComponent :activeTab="activeTab" @successApproved="onSuccessApproved"/>
        </b-col>
        <ManualAddProduct ref="manualAddProduct" @addProduct="addNewItem"/>
    </b-row>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

import WarehouseSearchElement from "../../../components/searchElements/WarehouseSearchElement";
import ClientSearchElement from "../../../components/searchElements/ClientSearchElement";
import SaleClientPaymentComponent from "../../../components/sale/SaleClientPaymentComponent";
import SaleBasketListComponent from "../../../components/sale/SaleBasketListComponent";
import translate from "../../../translation/translate";
import SaleService from "../../../services/sale/sale.service";
import WarehouseItemBarcodeService from "../../../services/warehouse/warehouse.item.barcode.service";
import WarehouseItemService from "../../../services/warehouse/warehouse.item.service";
import SaleItemService from "../../../services/sale/sale.item.service";
import SaleRegisterService from "../../../services/sale/sale.register.service";
import ManualAddProduct from "../../../components/sale/ManualAddProduct";
import HotKeysInfo from "../../../components/additional/HotKeysInfo";
import GlobalEvents from "vue-global-events";
import SaleNavbarComponent from "../../../components/sale/SaleNavbarComponent";

export default {
    name: "NewSale",
    components: {
        SaleNavbarComponent,
        GlobalEvents,
        HotKeysInfo,
        SaleBasketListComponent,
        SaleClientPaymentComponent, ClientSearchElement, WarehouseSearchElement, ManualAddProduct
    },
    data() {
        return {
            translate,
            SaleTab: [],
            focusInField: null,
            isSubmitted: false,
            selectedRow: null,
        }
    },
    computed: {
        ...mapGetters({
            currentUser: "currentUser",
            defaultClient: 'getDefaultClient',
            defaultEmployee: 'getDefaultEmployee',
            selectedWarehouse: 'selectedWarehouse'
        }),
        activeTab() {
            return this.SaleTab.find(tab => tab.IsActive);
        },
        isActiveManualAddProduct() {
            return this.$refs.manualAddProduct.isShown;
        }
    },
    methods: {
        openManualAddItemModal() {
            this.$root.$emit('bv::show::modal', 'manual-add-modal', '#btnShow')
            this.$nextTick(function () {
                this.$refs.manualAddProduct.focus()
            })
        },
        onSuccessApproved() {
            let findIndex = this.SaleTab.findIndex(tab => tab.Sale.SaleId === this.activeTab.Sale.SaleId);
            this.SaleTab.splice(findIndex, 1);
            if (this.SaleTab.length > 0) {
                this.SaleTab[0].IsActive = true;
            } else {
                this.resetNewTab();
            }
        },
        resetNewTab() {
            this.SaleTab.push({
                Sale: {
                    CreatedAt: new Date(),
                    Client: this.defaultClient,
                    Employee: this.defaultEmployee,
                    IsApproved: false,
                    Description: '',
                    SaleNumber: null,
                    Warehouse: this.selectedWarehouse,
                    DiscountCurrencyValue: {
                        Currency: null,
                        CurrencyId: null,
                        CurrencyValueId: null,
                        Rate: null,
                        Value: 0
                    },
                    ClientSaleBonusUseCurrencyValue: {
                        Currency: null,
                        CurrencyId: null,
                        CurrencyValueId: null,
                        Rate: null,
                        Value: 0
                    },
                    ClientSaleBonusCurrencyValue: {
                        Currency: null,
                        CurrencyId: null,
                        CurrencyValueId: null,
                        Rate: null,
                        Value: 0
                    },
                },
                Items: [],
                ClientPayment: {
                    PaymentCashCurrencyValue: {
                        Currency: null,
                        CurrencyId: null,
                        CurrencyValueId: null,
                        Rate: null,
                        Value: 0
                    },
                    PaymentCardCurrencyValue: {
                        Currency: null,
                        CurrencyId: null,
                        CurrencyValueId: null,
                        Rate: null,
                        Value: 0
                    },
                    PaymentBankTransferCurrencyValue: {
                        Currency: null,
                        CurrencyId: null,
                        CurrencyValueId: null,
                        Rate: null,
                        Value: 0
                    },
                    HumoTransferCurrencyValue: {
                        Currency: null,
                        CurrencyId: null,
                        CurrencyValueId: null,
                        Rate: null,
                        Value: 0
                    },
                    OtherTransferCurrencyValue: {
                        Currency: null,
                        CurrencyId: null,
                        CurrencyValueId: null,
                        Rate: null,
                        Value: 0
                    },
                    Description: '',
                    Client: null,
                    CreatedAt: new Date(),
                    ClientPaymentId: null,
                    SystemTotalValue: null,
                    Username: null,
                },
                IsActive: true,
            });
        },
        onBarcodeScanned(barcode) {
            let vm = this;
            let marks = null;
            if (!barcode)
                return;
            if (barcode.length > 14) {
                marks = barcode;
                // markirovkani shtrix kodini olamiz
                barcode = barcode.slice(0, 14);
                // markirovkani 0 larini kesib tashimiz
                barcode = barcode.replace(/^0+|$/g, '');
            }
            WarehouseItemBarcodeService.get({
                barcode: barcode,
                warehouseId: vm.selectedWarehouse.WarehouseId,
            }).then((item) => {
                let NewItem = {
                    PriceCurrencyValue: item.Item.PriceCurrencyValue,
                    Quantity: 1,
                    Item: item,
                    DiscountCurrencyValue: {
                        Currency: null,
                        CurrencyId: null,
                        CurrencyValueId: null,
                        Rate: null,
                        Value: 0
                    },
                    Marks: marks
                }
                vm.addNewItem(NewItem);
            }).catch(error => {
                vm.getWarehouseItemBySku(barcode);
            });
        },
        getWarehouseItemBySku(barcode) {
            if (barcode.length !== 13)
                return;
            let sku = barcode.slice(1, 7);
            let weightMilligram = barcode.slice(7);
            while (weightMilligram.length > 0 && weightMilligram[0] === '0')
                weightMilligram = weightMilligram.substring(1);
            WarehouseItemService.getAll({
                warehouseId: this.selectedWarehouse.WarehouseId,
                type: 1,
                query: '',
                sku: sku,
                desc: true,
                sort: '',
                skip: 0,
                limit: null,

                stateeq: null,
                statelt: null,
                stategt: null,
                statelteq: null,
                stategteq: null,
                alert: null,
                category: null,
                tag: null,
            }).then(warehouseItems => {
                if (warehouseItems.length === 0)
                    return;
                let item = warehouseItems[0];
                let NewItem = {
                    PriceCurrencyValue: item.Item.PriceCurrencyValue,
                    Quantity: Math.round10(weightMilligram / 10000, -4),
                    Item: item,
                    DiscountCurrencyValue: {
                        Currency: null,
                        CurrencyId: null,
                        CurrencyValueId: null,
                        Rate: null,
                        Value: 0
                    }
                }
                this.addNewItem(NewItem);
            });
        },
        addNewItem(NewItem) {
            let vm = this;
            if (!this.activeTab.Sale.Client) {
                vm.$bvToast.toast(translate.translate("frequentlyRepeated", "KlientNeVibran"), {
                    title: translate.translate("frequentlyRepeated", "KlientPoUmolchaniyu"),
                    autoHideDelay: 500,
                    toaster: 'b-toaster-bottom-left',
                    variant: 'warning'
                });
            }
            if (vm.isSubmitted)
                return;
            vm.isSubmitted = true;
            if (vm.activeTab.Sale.SaleId) {
                let existItem = vm.activeTab.Items.find(item => item.WarehouseItem.Item.ItemId === NewItem.Item.Item.ItemId);
                if (existItem && !NewItem.Marks) {
                    existItem.Quantity = +existItem.Quantity + +NewItem.Quantity;
                    SaleItemService.edit(existItem.SaleItemId, {
                        sale: vm.activeTab.Sale,
                        saleItem: existItem
                    }).then((updatedItem) => {
                        vm.isSubmitted = false;
                        let findIndex = vm.activeTab.Items.findIndex(item => item.SaleItemId === updatedItem.SaleItemId);
                        vm.$set(vm.activeTab.Items, findIndex, updatedItem);
                        if (vm.$refs.manualAddProduct)
                            vm.$refs.manualAddProduct.clear();
                        vm.$bvToast.toast(translate.translate("frequentlyRepeated", "uspeshno"), {
                            title: translate.translate("frequentlyRepeated", "uspeshno"),
                            autoHideDelay: 300,
                            toaster: 'b-toaster-bottom-left',
                            variant: 'success'
                        });
                    });
                } else {
                    SaleItemService.create({sale: this.activeTab.Sale, saleItem: NewItem}).then(saleItem => {
                        vm.isSubmitted = false;
                        vm.activeTab.Items.unshift(saleItem);
                        if (vm.$refs.manualAddProduct)
                            vm.$refs.manualAddProduct.clear();
                        vm.$bvToast.toast(translate.translate("frequentlyRepeated", "uspeshno"), {
                            title: translate.translate("frequentlyRepeated", "uspeshno"),
                            autoHideDelay: 300,
                            toaster: 'b-toaster-bottom-left',
                            variant: 'success'
                        });
                    }).catch(function (error) {
                        vm.isSubmitted = false;
                    });
                }
            } else {
                SaleRegisterService.register({
                    CreatedAt: vm.activeTab.Sale.CreatedAt,
                    ClientId: vm.activeTab.Sale.Client.ClientId,
                    WarehouseId: vm.selectedWarehouse.WarehouseId,
                    Items: [NewItem],
                }).then(({Sale, SaleItems}) => {
                    vm.isSubmitted = false;
                    vm.activeTab.Sale = Sale;
                    vm.activeTab.Items = SaleItems;
                    if (vm.$refs.manualAddProduct)
                        vm.$refs.manualAddProduct.clear();
                    vm.$bvToast.toast(translate.translate("frequentlyRepeated", "uspeshno"), {
                        title: translate.translate("frequentlyRepeated", "uspeshno"),
                        autoHideDelay: 300,
                        toaster: 'b-toaster-bottom-left',
                        variant: 'success'
                    });
                }).catch(function (error) {
                    vm.isSubmitted = false;
                });
            }
        },
        loadActiveTabSaleRegister() {
            let vm = this;
            SaleRegisterService.get(vm.activeTab.Sale.SaleId).then(({sale, saleItems}) => {
                vm.activeTab.Sale = sale;
                vm.activeTab.Items = saleItems;
            }).catch((error) => {
                console.log(error);
            });
        },
    },
    created() {
        this.$barcodeScanner.init(this.onBarcodeScanned);
        this.resetNewTab();
    },
    destroyed() {
        this.$barcodeScanner.destroy()
    }
}
</script>

<style lang="scss">

</style>