<template>
    <b-card footer-class="m-0" body-class="py-1 soft-primary payment-card-body"
            style="height: calc(98vh - 50px); margin-top: 1vh;">
        <div class="p-0">
            <div class="w-100 d-flex align-items-center justify-content-between">
                <span>
                    {{ translate.translate("frequentlyRepeated", "koplate") }}:
                </span>
                <h4 class="fw-bold">
                    {{ toPay | decimal }}
                    {{ selectedCurrency ? selectedCurrency.Name : '' }}
                </h4>
            </div>
            <div class="w-100 d-flex align-items-center justify-content-between">
                <span>
                    {{ translate.translate("frequentlyRepeated", "vdolg") }}:
                </span>
                <h4 class="fw-bold">
                    {{ debt | decimal }}
                    {{ selectedCurrency ? selectedCurrency.Name : '' }}
                </h4>
            </div>
            <div class="w-100 d-flex align-items-center justify-content-between">
                <span>
                    {{ translate.translate("frequentlyRepeated", "sdachi") }}:
                </span>
                <h4 class="fw-bold">
                    {{ totalPayBack | decimal }}
                    {{ selectedCurrency ? selectedCurrency.Name : '' }}
                </h4>
            </div>
            <b-form-group class="mt-3">
                <b-form-group>
                    <label>{{ translate.translate("frequentlyRepeated", "oplataNalichnimi") }}</label>
                    <b-input-group>
                        <AppendCurrencyToInput
                            :price-currency-value="activeTab.ClientPayment.PaymentCashCurrencyValue"/>
                        <IMaskPriceInput v-model="activeTab.ClientPayment.PaymentCashCurrencyValue.Value"
                                         @focus="onFocusToPaymentInput(activeTab.ClientPayment.PaymentCashCurrencyValue)"
                                         @blur="() => { if (!activeTab.ClientPayment.PaymentCashCurrencyValue.Value ) activeTab.ClientPayment.PaymentCashCurrencyValue.Value = 0}"
                                         ref="paymentCashAmount"/>
                        <WholeAmountComponent
                            v-if="activeTab.Sale"
                            :debt="debt"
                            :payment-person="activeTab.ClientPayment"
                            :payment-type="'PaymentCashCurrencyValue'"
                        />
                    </b-input-group>
                </b-form-group>
            </b-form-group>
            <b-form-group>
                <label>{{ translate.translate("frequentlyRepeated", "oplataVBankovskiyKarti") }}</label>
                <b-input-group>
                    <AppendCurrencyToInput :price-currency-value="activeTab.ClientPayment.PaymentCardCurrencyValue"/>
                    <IMaskPriceInput v-model="activeTab.ClientPayment.PaymentCardCurrencyValue.Value"
                                     @focus="onFocusToPaymentInput(activeTab.ClientPayment.PaymentCardCurrencyValue)"
                                     @blur="() => { if (!activeTab.ClientPayment.PaymentCardCurrencyValue.Value ) activeTab.ClientPayment.PaymentCardCurrencyValue.Value = 0}"/>
                    <WholeAmountComponent
                        v-if="activeTab.Sale"
                        :debt="debt"
                        :payment-person="activeTab.ClientPayment"
                        :payment-type="'PaymentCardCurrencyValue'"
                    />
                </b-input-group>
            </b-form-group>
            <b-form-group>
                <label>{{ translate.translate("frequentlyRepeated", "oplataCherezKartaHumo") }}</label>
                <b-input-group>
                    <AppendCurrencyToInput :price-currency-value="activeTab.ClientPayment.HumoTransferCurrencyValue"/>
                    <IMaskPriceInput v-model="activeTab.ClientPayment.HumoTransferCurrencyValue.Value"
                                     @focus="onFocusToPaymentInput(activeTab.ClientPayment.HumoTransferCurrencyValue)"
                                     @blur="() => { if (!activeTab.ClientPayment.HumoTransferCurrencyValue.Value ) activeTab.ClientPayment.HumoTransferCurrencyValue.Value = 0}"/>
                    <WholeAmountComponent
                        v-if="activeTab.Sale"
                        :debt="debt"
                        :payment-person="activeTab.ClientPayment"
                        :payment-type="'HumoTransferCurrencyValue'"
                    />
                </b-input-group>
            </b-form-group>
            <div class="d-flex justify-content-between mt-3 align-items-center">
                <p>Распечатать чек</p>
                <switches id="tool-mode-switch" v-model="printCheck" theme="custom" class="vue-switcher-small"
                          color="primary"/>
            </div>
        </div>
        <template #footer>
            <b-row>
                <b-card @click="addNum(7)"
                        class="col-3 font-weight-bold h5 m-0 p-1 cursor-pointer rounded-0 text-center">
                    7
                </b-card>
                <b-card @click="addNum(8)"
                        class="col-3 font-weight-bold h5 m-0 p-1 cursor-pointer rounded-0 text-center">
                    8
                </b-card>
                <b-card @click="addNum(9)"
                        class="col-3 font-weight-bold h5 m-0 p-1 cursor-pointer rounded-0 text-center">
                    9
                </b-card>
                <b-card @click="del()" class="col-3 font-weight-bold h5 m-0 p-1 cursor-pointer rounded-0 text-center">
                    <b-icon-backspace-fill/>
                </b-card>
                <b-card @click="addNum(4)"
                        class="col-3 font-weight-bold h5 m-0 p-1 cursor-pointer rounded-0 text-center">
                    4
                </b-card>
                <b-card @click="addNum(5)"
                        class="col-3 font-weight-bold h5 m-0 p-1 cursor-pointer rounded-0 text-center">
                    5
                </b-card>
                <b-card @click="addNum(6)"
                        class="col-3 font-weight-bold h5 m-0 p-1 cursor-pointer rounded-0 text-center">
                    6
                </b-card>
                <b-card @click="clear()" class="col-3 font-weight-bold h5 m-0 p-1 cursor-pointer rounded-0 text-center">
                    C
                </b-card>
                <b-card @click="addNum(1)"
                        class="col-3 font-weight-bold h5 m-0 p-1 cursor-pointer rounded-0 text-center">
                    1
                </b-card>
                <b-card @click="addNum(2)"
                        class="col-3 font-weight-bold h5 m-0 p-1 cursor-pointer rounded-0 text-center">
                    2
                </b-card>
                <b-card @click="addNum(3)"
                        class="col-3 font-weight-bold h5 m-0 p-1 cursor-pointer rounded-0 text-center">
                    3
                </b-card>
                <b-card @click="toNextField()"
                        class="col-3 font-weight-bold h5 m-0 p-1 cursor-pointer rounded-0 text-center">Tab
                </b-card>
                <b-card @click="addNum(0)"
                        class="col-3 font-weight-bold h5 m-0 p-1 cursor-pointer rounded-0 text-center">
                    0
                </b-card>
                <b-card @click="addNum('00')"
                        class="col-3 font-weight-bold h5 m-0 p-1 cursor-pointer rounded-0 text-center">00
                </b-card>
                <b-button @click="saveSale"
                          class="col-6 font-weight-bold h5 m-0 p-1 cursor-pointer rounded-0 text-center border-0"
                          :disabled="isSubmitted" variant="info">
                    <b-spinner v-if="isSubmitted" small></b-spinner>
                    <span class="h5 text-white">Оплатить</span>
                </b-button>
            </b-row>
        </template>
    </b-card>
</template>

<script>
import Switches from 'vue-switches';
import AppendCurrencyToInput from "../currency/AppendCurrencyToInput";
import IMaskPriceInput from "../imask/IMaskPriceInput";
import WholeAmountComponent from "./WholeAmountComponent";
import math from "../../plugins/math";
import translate from "../../translation/translate";
import {mapGetters} from "vuex";
import ClientPaymentService from "../../services/client/client.payment.service";
import SaleService from "../../services/sale/sale.service";
import CompleteSaleService from "../../services/sale/complete.sale.service";
import Downloader from "../../downloader";
import EPosMixin from "../../mixins/epos.mixin";

export default {
    name: "SaleClientPaymentComponent",
    components: {WholeAmountComponent, IMaskPriceInput, AppendCurrencyToInput, Switches},
    mixins: [EPosMixin],
    props: {
        activeTab: {
            default: {},
            type: Object
        }
    },
    data() {
        return {
            translate,
            printCheck: true,
            typeDiscount: 0,
            isSubmitted: false
        }
    },
    computed: {
        ...mapGetters({
            currencies: "getAllCurrencies",
            selectedCurrency: "selectedCurrency",
            defaultEmployee: 'getDefaultEmployee'
        }),
        totalPayBack() {
            let paymentCashAmount = this.activeTab.ClientPayment.PaymentCashCurrencyValue.CurrencyId && !this.selectedCurrency ?
                math.multiply(+this.activeTab.ClientPayment.PaymentCashCurrencyValue.Value, +this.activeTab.ClientPayment.PaymentCashCurrencyValue.Rate)

                : !this.activeTab.ClientPayment.PaymentCashCurrencyValue.CurrencyId && this.selectedCurrency ?
                    this.$options.filters.division(this.activeTab.ClientPayment.PaymentCashCurrencyValue.Value)

                    : +this.activeTab.ClientPayment.PaymentCashCurrencyValue.Value;

            let paymentCardAmount = this.activeTab.ClientPayment.PaymentCardCurrencyValue.CurrencyId && !this.selectedCurrency ?
                math.multiply(+this.activeTab.ClientPayment.PaymentCardCurrencyValue.Value, +this.activeTab.ClientPayment.PaymentCardCurrencyValue.Rate)

                : !this.activeTab.ClientPayment.PaymentCardCurrencyValue.CurrencyId && this.selectedCurrency ?
                    this.$options.filters.division(this.activeTab.ClientPayment.PaymentCardCurrencyValue.Value)

                    : +this.activeTab.ClientPayment.PaymentCardCurrencyValue.Value;

            let paymentBankTransferAmount = this.activeTab.ClientPayment.PaymentBankTransferCurrencyValue.CurrencyId && !this.selectedCurrency ?
                math.multiply(+this.activeTab.ClientPayment.PaymentBankTransferCurrencyValue.Value, +this.activeTab.ClientPayment.PaymentBankTransferCurrencyValue.Rate)

                : !this.activeTab.ClientPayment.PaymentBankTransferCurrencyValue.CurrencyId && this.selectedCurrency ?
                    this.$options.filters.division(this.activeTab.ClientPayment.PaymentBankTransferCurrencyValue.Value)

                    : +this.activeTab.ClientPayment.PaymentBankTransferCurrencyValue.Value;

            let humoTransferAmount = this.activeTab.ClientPayment.HumoTransferCurrencyValue.CurrencyId && !this.selectedCurrency ?
                math.multiply(+this.activeTab.ClientPayment.HumoTransferCurrencyValue.Value, +this.activeTab.ClientPayment.HumoTransferCurrencyValue.Rate)

                : !this.activeTab.ClientPayment.HumoTransferCurrencyValue.CurrencyId && this.selectedCurrency ?
                    this.$options.filters.division(this.activeTab.ClientPayment.HumoTransferCurrencyValue.Value)

                    : +this.activeTab.ClientPayment.HumoTransferCurrencyValue.Value;

            let otherTransferAmount = this.activeTab.ClientPayment.OtherTransferCurrencyValue.CurrencyId && !this.selectedCurrency ?
                math.multiply(+this.activeTab.ClientPayment.OtherTransferCurrencyValue.Value, +this.activeTab.ClientPayment.OtherTransferCurrencyValue.Rate)

                : !this.activeTab.ClientPayment.OtherTransferCurrencyValue.CurrencyId && this.selectedCurrency ?
                    this.$options.filters.division(this.activeTab.ClientPayment.OtherTransferCurrencyValue.Value)

                    : +this.activeTab.ClientPayment.OtherTransferCurrencyValue.Value;

            let clientSaleBonusUseAmount = this.activeTab.Sale.ClientSaleBonusUseCurrencyValue.CurrencyId && !this.selectedCurrency ?
                math.multiply(+this.activeTab.Sale.ClientSaleBonusUseCurrencyValue.Value, +this.activeTab.Sale.ClientSaleBonusUseCurrencyValue.Rate)
                : +this.activeTab.Sale.ClientSaleBonusUseCurrencyValue.Value;

            let totalPayBack = (paymentCashAmount
                + paymentCardAmount
                + paymentBankTransferAmount
                + humoTransferAmount
                + otherTransferAmount
                + clientSaleBonusUseAmount) - this.toPay;

            if (totalPayBack < 0)
                totalPayBack = 0;

            return totalPayBack;
        },
        Total() {
            let total = 0;
            this.activeTab.Items.forEach(item => {
                let value = item.PriceCurrencyValue.Rate ?
                    math.multiply(item.PriceCurrencyValue.Value, item.PriceCurrencyValue.Rate) :
                    item.PriceCurrencyValue.Value;
                total += math.multiply(value, item.Quantity);
            });
            return total;
        },
        toPay() {
            let f = this.$options.filters.division(this.Total);
            let totalWithCurrency = Math.round10(f, -4);

            let discountCurrencyValue = this.activeTab.Sale.DiscountCurrencyValue.CurrencyId && !this.selectedCurrency ?
                math.multiply(this.activeTab.Sale.DiscountCurrencyValue.Value, this.activeTab.Sale.DiscountCurrencyValue.Rate) : this.activeTab.Sale.DiscountCurrencyValue.Value;

            if (this.typeDiscount === 0) {
                return totalWithCurrency - discountCurrencyValue;
            } else if (this.typeDiscount === 1) {
                return totalWithCurrency - math.multiply(totalWithCurrency, discountCurrencyValue) / 100;
            }
        },
        debt() {
            let paymentCashAmount = this.activeTab.ClientPayment.PaymentCashCurrencyValue.CurrencyId && !this.selectedCurrency ?
                math.multiply(+this.activeTab.ClientPayment.PaymentCashCurrencyValue.Value, +this.activeTab.ClientPayment.PaymentCashCurrencyValue.Rate)
                : +this.activeTab.ClientPayment.PaymentCashCurrencyValue.Value;

            let paymentCardAmount = this.activeTab.ClientPayment.PaymentCardCurrencyValue.CurrencyId && !this.selectedCurrency ?
                math.multiply(+this.activeTab.ClientPayment.PaymentCardCurrencyValue.Value, +this.activeTab.ClientPayment.PaymentCardCurrencyValue.Rate)
                : +this.activeTab.ClientPayment.PaymentCardCurrencyValue.Value;

            let paymentBankTransferAmount = this.activeTab.ClientPayment.PaymentBankTransferCurrencyValue.CurrencyId && !this.selectedCurrency ?
                math.multiply(+this.activeTab.ClientPayment.PaymentBankTransferCurrencyValue.Value, +this.activeTab.ClientPayment.PaymentBankTransferCurrencyValue.Rate)
                : +this.activeTab.ClientPayment.PaymentBankTransferCurrencyValue.Value;

            let humoTransferAmount = this.activeTab.ClientPayment.HumoTransferCurrencyValue.CurrencyId && !this.selectedCurrency ?
                math.multiply(+this.activeTab.ClientPayment.HumoTransferCurrencyValue.Value, +this.activeTab.ClientPayment.HumoTransferCurrencyValue.Rate)
                : +this.activeTab.ClientPayment.HumoTransferCurrencyValue.Value;

            let otherTransferAmount = this.activeTab.ClientPayment.OtherTransferCurrencyValue.CurrencyId && !this.selectedCurrency ?
                math.multiply(+this.activeTab.ClientPayment.OtherTransferCurrencyValue.Value, +this.activeTab.ClientPayment.OtherTransferCurrencyValue.Rate)
                : +this.activeTab.ClientPayment.OtherTransferCurrencyValue.Value;

            let clientSaleBonusUseAmount = this.activeTab.Sale.ClientSaleBonusUseCurrencyValue.CurrencyId && !this.selectedCurrency ?
                math.multiply(+this.activeTab.Sale.ClientSaleBonusUseCurrencyValue.Value, +this.activeTab.Sale.ClientSaleBonusUseCurrencyValue.Rate)
                : +this.activeTab.Sale.ClientSaleBonusUseCurrencyValue.Value;

            let debt = this.toPay - (paymentCashAmount + paymentCardAmount + paymentBankTransferAmount
                + humoTransferAmount + otherTransferAmount + clientSaleBonusUseAmount);

            if (debt < 0)
                debt = 0;
            return debt;
        },
    },
    methods: {
        onFocusToPaymentInput(PaymentCurrencyValue) {
            if (!PaymentCurrencyValue.Value || PaymentCurrencyValue.Value === '0') PaymentCurrencyValue.Value = '';
        },
        clear() {
            this.activeTab.ClientPayment.PaymentCashCurrencyValue.Value = 0;
            this.activeTab.ClientPayment.PaymentCardCurrencyValue.Value = 0;
            this.activeTab.ClientPayment.HumoTransferCurrencyValue.Value = 0;
            this.focusInField = null;
        },
        saveSale() {
            let vm = this;
            if (!vm.activeTab.Sale.SaleId)
                return;
            vm.isSubmitted = true;

            if (vm.totalPayBack > 0 && vm.activeTab.ClientPayment.PaymentCashCurrencyValue.Value > 0) {
                vm.activeTab.ClientPayment.PaymentCashCurrencyValue.Value = vm.activeTab.ClientPayment.PaymentCashCurrencyValue.Value - vm.totalPayBack;
            }

            let data = {
                Description: '',
                ClientId: this.activeTab.Sale.Client.ClientId,
                CreatedAt: new Date(this.activeTab.Sale.CreatedAt),
                PaymentCashCurrencyValue: this.activeTab.ClientPayment.PaymentCashCurrencyValue,
                PaymentCardCurrencyValue: this.activeTab.ClientPayment.PaymentCardCurrencyValue,
                PaymentBankTransferCurrencyValue: this.activeTab.ClientPayment.PaymentBankTransferCurrencyValue,
                HumoTransferCurrencyValue: this.activeTab.ClientPayment.HumoTransferCurrencyValue,
                OtherTransferCurrencyValue: this.activeTab.ClientPayment.OtherTransferCurrencyValue,
            }
            ClientPaymentService.create(data).then(res => {
                vm.activeTab.Sale.IsApproved = true;
                vm.activeTab.Sale.ClientPaymentId = res.ClientPaymentId;

                if (vm.typeDiscount === 1) {
                    let discount = vm.activeTab.Sale.DiscountCurrencyValue.Value / 100;
                    vm.activeTab.Sale.DiscountCurrencyValue.Value = math.multiply(vm.Total, discount);
                }
                SaleService.edit(vm.activeTab.Sale).then(async sale => {
                    await vm.completeSale();
                    if (vm.printCheck) {
                        await Downloader.downloadSalePdf(sale, true);
                    }
                    await vm.sendSaleToEPos(sale.SaleId);
                    vm.isSubmitted = false;
                    vm.$emit('successApproved');
                });
            });
        },
        async completeSale() {
            await CompleteSaleService.edit(this.activeTab.Sale.SaleId, {
                EmployeeId: this.defaultEmployee ? this.defaultEmployee.EmployeeId : null,
                Description: this.activeTab.Sale.Description,
                SaleNumber: this.activeTab.Sale.SaleNumber,
                ClientPaymentId: this.activeTab.Sale.ClientPaymentId || null,
                DiscountCurrencyValue: {
                    Value: +(this.typeDiscount === 0 ? this.activeTab.Sale.DiscountCurrencyValue.Value : math.multiply(this.Total, (this.activeTab.Sale.DiscountCurrencyValue.Value / 100))) || 0,
                    Rate: this.activeTab.Sale.DiscountCurrencyValue.Rate,
                    CurrencyId: this.activeTab.Sale.DiscountCurrencyValue.CurrencyId,
                },
                ClientSaleBonusUseCurrencyValue: this.activeTab.Sale.ClientSaleBonusUseCurrencyValue,
                ClientSaleBonusCurrencyValue: this.activeTab.Sale.ClientSaleBonusCurrencyValue,
                RegistrationType: 1,
            });
        },
        toNextField() {
            let vm = this;
            if (vm.focusInField === 'PaymentCashCurrencyValue') {
                vm.focusInField = 'PaymentCardCurrencyValue'
            } else if (vm.focusInField === 'PaymentCardCurrencyValue') {
                vm.focusInField = 'HumoTransferCurrencyValue'
            } else {
                vm.focusInField = 'PaymentCashCurrencyValue'
            }
        },
        del() {
            if (this.focusInField && this.focusInField === 'PaymentCardCurrencyValue') {
                this.activeTab.ClientPayment.PaymentCardCurrencyValue.Value = this.activeTab.ClientPayment.PaymentCardCurrencyValue.Value.slice(0, this.activeTab.ClientPayment.PaymentCardCurrencyValue.Value.length - 1);
            } else if (this.focusInField && this.focusInField === 'HumoTransferCurrencyValue') {
                this.activeTab.ClientPayment.HumoTransferCurrencyValue.Value = this.activeTab.ClientPayment.HumoTransferCurrencyValue.Value.slice(0, this.activeTab.ClientPayment.HumoTransferCurrencyValue.Value.length - 1);
            } else {
                this.activeTab.ClientPayment.PaymentCashCurrencyValue.Value = this.activeTab.ClientPayment.PaymentCashCurrencyValue.Value.slice(0, this.activeTab.ClientPayment.PaymentCashCurrencyValue.Value.length - 1);
            }
        },
        addNum(key) {
            if (this.focusInField && this.focusInField === 'PaymentCardCurrencyValue') {
                this.activeTab.ClientPayment.PaymentCardCurrencyValue.Value = String(this.activeTab.ClientPayment.PaymentCardCurrencyValue.Value + key).slice(0);
            } else if (this.focusInField && this.focusInField === 'HumoTransferCurrencyValue') {
                this.activeTab.ClientPayment.HumoTransferCurrencyValue.Value = String(this.activeTab.ClientPayment.HumoTransferCurrencyValue.Value + key).slice(0);
            } else {
                this.activeTab.ClientPayment.PaymentCashCurrencyValue.Value = String(this.activeTab.ClientPayment.PaymentCashCurrencyValue.Value + key).slice(0);
            }
        },
    },
}
</script>

<style scoped>

</style>