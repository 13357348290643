import BaseService from "../base/base.service";
import axios from "axios";

class EPosSaleService extends BaseService {
    get modelName() {
        return 'EPosSale'
    }

    get(saleId) {
        return axios.get(this.getSingleUrl(saleId));
    }

    edit(saleId, params) {
        return axios.put(this.getEditUrl(saleId), params);
    }
}

export default new EPosSaleService();
