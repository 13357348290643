<template>
    <div>
        <b-card class="soft-primary my-2">
            <b-row>
                <b-col md="8">
                    <b-input :placeholder='translation.translate("frequentlyRepeated", "Poisk")'
                             v-model="search"
                             autofocus
                             @input="searchDebounce()"/>
                </b-col>
                <b-col md="4" class="ml-auto text-right">
                    <TableLimit :limit="limit" @newLimit="onChangeLimit"/>
                </b-col>
            </b-row>
        </b-card>
        <b-row>
            <b-col cols="12">
                <b-card>
                    <b-overlay :show="isLoadedSales" spinner-variant="primary">
                        <b-table
                            hover
                            small
                            ref="datatable"
                            :items="sales"
                            :fields="fields"
                            sort-icon-left
                            no-sort-reset
                            :sort-by="sortBy"
                            :sort-desc="sortDesc"
                            @sort-changed="sortingChanged"
                            :no-footer-sorting="true"
                            show-empty
                            :responsive="true"
                            class="hp-data-table position-relative">
                            <template v-slot:head(actions)>
                                <i class="iconsminds-gear h6 cursor-pointer" v-b-modal.modal-setting-table
                                   :title='translation.translate("frequentlyRepeated", "NastroykiTablisi")'></i>
                            </template>
                            <template v-slot:cell(#)="scope">
                                {{ (page - 1) * limit + scope.index + 1 }}
                            </template>
                            <template v-slot:cell(saleNumber)="scope">
                                {{ scope.item.SaleNumber }}
                            </template>
                            <template v-slot:cell(isApproved)="scope">
                                <b-badge :variant="scope.item.IsApproved ? 'success' : 'warning'">
                                    {{
                                        scope.item.IsApproved ? translation.translate("frequentlyRepeated", "podverjden") :
                                            translation.translate("frequentlyRepeated", "nepodverjden")
                                    }}
                                </b-badge>
                            </template>
                            <template v-slot:cell(client)="scope">
                                {{ scope.item.Client.Name }}
                            </template>
                            <template v-slot:cell(clientPayment)="scope">
                                <b-badge :variant="scope.item.ClientPaymentId ? 'success':'primary'">
                                    <span v-if="scope.item.ClientPaymentId">Оплачено</span>
                                    <span v-else>Не оплачено</span>
                                </b-badge>
                            </template>
                            <template v-slot:cell(total)="scope">
                                {{ scope.item.Total | division | decimal }}
                                {{
                                    selectedCurrency ? selectedCurrency.Name : ''
                                }}
                            </template>
                            <template v-slot:cell(totalPaid)="scope">
                                <div v-if="scope.item.ClientPayment && scope.item.ClientPayment.SystemTotalValue">
                                    {{
                                        scope.item.ClientPayment.SystemTotalValue | division | decimal
                                    }}
                                    {{
                                        selectedCurrency ? selectedCurrency.Name : ''
                                    }}
                                </div>
                                <div v-else>0</div>
                            </template>
                            <template v-slot:cell(paymentCashAmount)="scope">
                                <div
                                    v-if="scope.item.ClientPayment && scope.item.ClientPayment.PaymentCashCurrencyValue">
                                    {{
                                        scope.item.ClientPayment.PaymentCashCurrencyValue.Value | division(scope.item.ClientPayment.PaymentCashCurrencyValue.Currency) | decimal
                                    }}
                                    {{
                                        scope.item.ClientPayment.PaymentCashCurrencyValue && scope.item.ClientPayment.PaymentCashCurrencyValue.Currency ?
                                            scope.item.ClientPayment.PaymentCashCurrencyValue.Currency.Name : ''
                                    }}
                                </div>
                                <div v-else>0</div>
                            </template>
                            <template v-slot:cell(paymentCardAmount)="scope">
                                <div
                                    v-if="scope.item.ClientPayment && scope.item.ClientPayment.PaymentCardCurrencyValue">
                                    {{
                                        scope.item.ClientPayment.PaymentCardCurrencyValue.Value | division(scope.item.ClientPayment.PaymentCardCurrencyValue.Currency) | decimal
                                    }}
                                    {{
                                        scope.item.ClientPayment.PaymentCardCurrencyValue && scope.item.ClientPayment.PaymentCardCurrencyValue.Currency ? scope.item.ClientPayment.PaymentCardCurrencyValue.Currency.Name : ''
                                    }}
                                </div>
                                <div v-else>0</div>
                            </template>
                            <template v-slot:cell(humoTransferAmount)="scope">
                                <div
                                    v-if="scope.item.ClientPayment && scope.item.ClientPayment.HumoTransferCurrencyValue">
                                    {{
                                        scope.item.ClientPayment.HumoTransferCurrencyValue.Value | division(scope.item.ClientPayment.HumoTransferCurrencyValue.Currency) | decimal
                                    }}
                                    {{
                                        scope.item.ClientPayment.HumoTransferCurrencyValue && scope.item.ClientPayment.HumoTransferCurrencyValue.Currency ? scope.item.ClientPayment.HumoTransferCurrencyValue.Currency.Name : ''
                                    }}
                                </div>
                                <div v-else>0</div>
                            </template>
                            <template v-slot:cell(createdAt)="scope">
                                {{ scope.item.CreatedAt | datetime }}
                            </template>
                            <template v-slot:cell(account)="scope">{{ scope.item.Username }}</template>
                            <template v-slot:empty>
                                <h6 class="text-center">{{
                                        translation.translate("frequentlyRepeated", "netDanniyx")
                                    }}</h6>
                            </template>
                        </b-table>
                    </b-overlay>
                    <Pagination :page="page" :length="sales.length" :limit="limit" @prevPage="onPrev()"
                                @nextPage="onNext()"></Pagination>
                </b-card>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import translation from "../../../translation/translate";
import SaleService from "../../../services/sale/sale.service"
import {mapGetters} from "vuex";
import Pagination from "../../../components/additional/Pagination";
import TableLimit from "../../../components/TableLimit";
import {saleDataTableFields} from "../../../constants/datatable_fields";
import {debounce} from "underscore";

export default {
    components: {TableLimit, Pagination},
    data() {
        return {
            translation: translation,
            selectedStatusFilter: {value: 0, text: translation.translate("frequentlyRepeated", "vseProdoja")},
            isLoadedSales: false,
            search: this.$route.query && this.$route.query.search ? this.$route.query.search : '',
            sortBy: this.$route.query && this.$route.query.sortBy ? this.$route.query.sortBy : 'createdAt',
            sortDesc: this.$route.query && this.$route.query.sortDesc === 'false' ? false : true,
            page: this.$route.query && this.$route.query.page ? +this.$route.query.page : 1,
            limit: localStorage.getItem('limit') || '10',
            client: null,
            employee: null,
            account: null,
            startDate: this.$route.query && this.$route.query.startDate ? new Date(+this.$route.query.startDate) : new Date(),
            endDate: this.$route.query && this.$route.query.endDate ? new Date(+this.$route.query.endDate) : new Date(),
            sales: [],
            fields: saleDataTableFields,
        }
    },
    computed: {
        ...mapGetters({
            currencies: "getAllCurrencies",
            selectedCurrency: "selectedCurrency",
            selectedWarehouse: "selectedWarehouse",
            statusEPos: 'getStatusEPos',
            organization: "getOrganization"
        }),
        dataTableFields() {
            return this.fields.filter(field => field.checked && (field.haveAccess || typeof field.haveAccess === 'undefined'));
        },
        clientId() {
            if (this.$route.query && this.$route.query.client) {
                return this.$route.query.client;
            }
            if (this.client) {
                return this.client.ClientId;
            }
            if (this.$route.name === 'client-sale' && this.$route.params.id) {
                return this.$route.params.id;
            }
            return null;
        },
        employeeId() {
            if (this.$route.query && this.$route.query.employee) {
                return this.$route.query.employee;
            }
            if (this.employee) {
                return this.employee.EmployeeId;
            }
            return null;
        }
    },
    methods: {
        searchDebounce: debounce(function () {
            this.page = 1;
            this.update();
        }, 1000),
        onChangeLimit(selectedLimit) {
            let vm = this;
            vm.limit = selectedLimit;
            vm.update();
        },
        onPrev() {
            this.page = this.page - 1;
            this.update();
        },
        onNext() {
            this.page = this.page + 1;
            this.update();
        },
        sortingChanged(ctx) {
            this.sortBy = ctx.sortBy;
            this.sortDesc = ctx.sortDesc;
            this.update();
        },
        updateRouteQuery({
                             client,
                             employee,
                             account,
                             search,
                             sortBy,
                             sortDesc,
                             limit,
                             startDate,
                             endDate,
                             selectedStatusFilter,
                             page
                         }) {
            let routeQuery = {}
            routeQuery.sortDesc = sortDesc;
            if (client) {
                routeQuery.client = client
            }
            if (employee) {
                routeQuery.employee = employee
            }
            if (account) {
                routeQuery.account = account
            }
            if (search) {
                routeQuery.search = search
            }
            if (sortBy) {
                routeQuery.sortBy = sortBy
            }
            if (limit) {
                routeQuery.limit = limit
            }
            if (startDate) {
                routeQuery.startDate = startDate
            }
            if (endDate) {
                routeQuery.endDate = endDate
            }
            if (selectedStatusFilter) {
                routeQuery.selectedStatusFilter = selectedStatusFilter
            }
            if (page) {
                routeQuery.page = page;
            }
            this.$router.replace({name: this.$route.name, query: routeQuery}).catch(() => {
            });
        },
        update() {
            this.updateRouteQuery({
                client: this.client ? this.client.ClientId : null,
                employee: this.employee ? this.employee.EmployeeId : null,
                account: this.account ? this.account.Username : null,
                search: this.search ? this.search : null,
                sortBy: this.sortBy ? this.sortBy : null,
                sortDesc: this.sortDesc,
                limit: this.limit,
                startDate: this.startDate ? this.startDate.getTime() : null,
                endDate: this.endDate ? this.endDate.getTime() : null,
                selectedStatusFilter: this.selectedStatusFilter.value,
                page: this.page
            });
            this.loadSales();
        },
        loadSales() {
            let vm = this;
            let approved = null;
            let debt = null;
            if (this.selectedStatusFilter.value === 0) {
                approved = null;
                debt = null;
            } else if (this.selectedStatusFilter.value === 1) {
                approved = true;
                debt = null;
            } else if (this.selectedStatusFilter.value === 2) {
                approved = false;
                debt = null;
            } else if (this.selectedStatusFilter.value === 3) {
                approved = true;
                debt = true;
            }
            vm.isLoadedSales = true;
            SaleService.getAll({
                query: vm.search,
                desc: vm.sortDesc,
                sort: vm.sortBy ? this.sortBy : '',
                skip: (vm.page - 1) * +this.limit,
                limit: vm.limit,
                client: vm.clientId,
                employee: vm.employeeId,
                account: vm.account ? vm.account.Username : null,
                startDate: vm.startDate,
                endDate: vm.endDate,

                approved: approved,
                debt: debt,

                warehouse: vm.selectedWarehouse.WarehouseId
            }).then(sales => {
                vm.sales = sales;
                vm.isLoadedSales = false;
            }, function (error) {
                vm.isLoadedSales = false;
            });
        },
    },
    mounted() {
        this.loadSales();
    },
    beforeDestroy() {
        this.$off("prevPage");
        this.$off("nextPage");
    },
}
</script>