import {ApiHost} from '@/constants/settings'
import axios from "axios";
import printJs from "print-js";
import store from "../store";

class Downloader {
    downloadAllClientsToExcel(urlParams) {
        let url = '';
        if (urlParams.startDate) {
            urlParams.lastSaleStart = this._getDateDDMMYYYFormat(urlParams.startDate);
        }
        if (urlParams.endDate) {
            urlParams.lastSaleEnd = this._getDateDDMMYYYFormat(urlParams.endDate);
        }
        url = `${ApiHost}/api/ClientExcel`
        this._downloadUrl(url, false, urlParams);
    }

    downloadAllClientPaymentsToExcel(urlParams) {
        let url = '';
        if (urlParams.startDate) {
            urlParams.start = this._getDateDDMMYYYFormat(urlParams.startDate);
            delete urlParams.startDate;
        }
        if (urlParams.endDate) {
            urlParams.end = this._getDateDDMMYYYFormat(urlParams.endDate);
            delete urlParams.endDate;
        }
        url = `${ApiHost}/api/ClientPaymentExcel`
        this._downloadUrl(url, false, urlParams);
    }

    downloadAllTransfersToExcel(urlParams) {
        if (urlParams.startDate) {
            urlParams.start = this._getDateDDMMYYYFormat(urlParams.startDate);
            delete urlParams.startDate;
        }
        if (urlParams.endDate) {
            urlParams.end = this._getDateDDMMYYYFormat(urlParams.endDate);
            delete urlParams.endDate;
        }
        this._downloadUrl(`${ApiHost}/api/TransferExcel`, false, urlParams);
    }

    downloadAllProvidersToExcel(urlParams) {
        let url = '';
        url = `${ApiHost}/api/ProviderExcel`;
        this._downloadUrl(url, false, urlParams);
    }

    downloadWarehouseItemExcel(urlParams) {
        let url = '';
        let selectedCurrency = store.getters.selectedCurrency;
        if (selectedCurrency)
            url = `${ApiHost}/api/WarehouseItemReport/?currencyId=${selectedCurrency.CurrencyId}`;
        else
            url = `${ApiHost}/api/WarehouseItemReport`;
        this._downloadUrl(url, false, urlParams);
    }
    downloadSaleExcel(Sale) {
        let url = '';
        let selectedCurrency = store.getters.selectedCurrency;
        if (selectedCurrency)
            url = `${ApiHost}/api/SaleExcel/${Sale.SaleId}?currencyId=${selectedCurrency.CurrencyId}`;
        else
            url = `${ApiHost}/api/SaleExcel/${Sale.SaleId}`;
        this._downloadUrl(url);
    }

    downloadSalePdf(Sale, isPrint) {
        let url = '';
        let selectedCurrency = store.getters.selectedCurrency;
        if (selectedCurrency)
            url = `${ApiHost}/api/SalePdf/${Sale.SaleId}?currencyId=${selectedCurrency.CurrencyId}`;
        else
            url = `${ApiHost}/api/SalePdf/${Sale.SaleId}`;
        return this._downloadUrl(url, isPrint);
    }

    downloadPurchaseExcel(purchase) {
        let url = '';
        let selectedCurrency = store.getters.selectedCurrency;
        if (selectedCurrency)
            url = `${ApiHost}/api/PurchaseExcel/${purchase.PurchaseId}?currencyId=${selectedCurrency.CurrencyId}`;
        else
            url = `${ApiHost}/api/PurchaseExcel/${purchase.PurchaseId}`;
        this._downloadUrl(url);
    }

    downloadTransferExcel(transfer) {
        let urlParams = '';
        let selectedCurrency = store.getters.selectedCurrency;
        if (selectedCurrency)
            urlParams = `${ApiHost}/api/TransferExcel/${transfer.TransferId}?currencyId=${selectedCurrency.CurrencyId}`;
        else
            urlParams = `${ApiHost}/api/TransferExcel/${transfer.TransferId}`;
        this._downloadUrl(urlParams);
    }

    downloadPurchasePdf(purchase, isPrint) {
        let url = '';
        let selectedCurrency = store.getters.selectedCurrency;
        if (selectedCurrency)
            url = `${ApiHost}/api/PurchasePdf/${purchase.PurchaseId}?currencyId=${selectedCurrency.CurrencyId}`;
        else
            url = `${ApiHost}/api/PurchasePdf/${purchase.PurchaseId}`;

        this._downloadUrl(url, isPrint);
    }

    downloadRefundExcel(refund) {
        let url = '';
        let selectedCurrency = store.getters.selectedCurrency;
        if (selectedCurrency)
            url = `${ApiHost}/api/RefundExcel/${refund.RefundId}?currencyId=${selectedCurrency.CurrencyId}`;
        else
            url = `${ApiHost}/api/RefundExcel/${refund.RefundId}`;
        this._downloadUrl(url);
    }

    downloadRefundPdf(refund, isPrint) {
        let url = '';
        let selectedCurrency = store.getters.selectedCurrency;
        if (selectedCurrency)
            url = `${ApiHost}/api/RefundPdf/${refund.RefundId}?currencyId=${selectedCurrency.CurrencyId}`;
        else
            url = `${ApiHost}/api/RefundPdf/${refund.RefundId}`;

        this._downloadUrl(url, isPrint);
    }

    downloadOrderExcel(order) {
        let url = '';
        let selectedCurrency = store.getters.selectedCurrency;
        if (selectedCurrency)
            url = `${ApiHost}/api/OrderExcel/${order.OrderId}?currencyId=${selectedCurrency.CurrencyId}`;
        else
            url = `${ApiHost}/api/OrderExcel/${order.OrderId}`;
        this._downloadUrl(url);
    }

    downloadOrderPdf(order, isPrint) {
        let url = '';
        let selectedCurrency = store.getters.selectedCurrency;
        if (selectedCurrency)
            url = `${ApiHost}/api/OrderPdf/${order.OrderId}?currencyId=${selectedCurrency.CurrencyId}`;
        else
            url = `${ApiHost}/api/OrderPdf/${order.OrderId}`;
        this._downloadUrl(url, isPrint);
    }

    downloadTransferPdf(transfer, isPrint) {
        let url = '';
        let selectedCurrency = store.getters.selectedCurrency;
        if (selectedCurrency)
            url = `${ApiHost}/api/TransferPdf/${transfer.TransferId}?currencyId=${selectedCurrency.CurrencyId}`;
        else
            url = `${ApiHost}/api/TransferPdf/${transfer.TransferId}`;

        this._downloadUrl(url, isPrint);
    }

    downloadClientReconciliationActPdf(ClientId, Start, End) {
        let url = '';
        let selectedCurrency = store.getters.selectedCurrency;
        let startDate = this._getDateDDMMYYYFormat(Start);
        let endDate = this._getDateDDMMYYYFormat(End);
        if (selectedCurrency)
            url = `${ApiHost}/api/ClientReconciliationActPdf/${ClientId}?start=${startDate}&end=${endDate}&currencyId=${selectedCurrency.CurrencyId}`;
        else
            url = `${ApiHost}/api/ClientReconciliationActPdf/${ClientId}?start=${startDate}&end=${endDate}`;
        this._downloadUrl(url);
    }

    downloadClientReconciliationItemActExcel(ClientId, Start, End) {
        let url = '';
        let selectedCurrency = store.getters.selectedCurrency;
        let startDate = this._getDateDDMMYYYFormat(Start);
        let endDate = this._getDateDDMMYYYFormat(End);
        if (selectedCurrency)
            url = `${ApiHost}/api/ClientReconciliationItemActExcel/${ClientId}?start=${startDate}&end=${endDate}&currencyId=${selectedCurrency.CurrencyId}`;
        else
            url = `${ApiHost}/api/ClientReconciliationItemActExcel/${ClientId}?start=${startDate}&end=${endDate}`;
        this._downloadUrl(url);
    }

    downloadClientReconciliationActExcel(ClientId, Start, End) {
        let url = '';
        let selectedCurrency = store.getters.selectedCurrency;
        let startDate = this._getDateDDMMYYYFormat(Start);
        let endDate = this._getDateDDMMYYYFormat(End);
        if (selectedCurrency)
            url = `${ApiHost}/api/ClientReconciliationActExcel/${ClientId}?start=${startDate}&end=${endDate}&currencyId=${selectedCurrency.CurrencyId}`;
        else
            url = `${ApiHost}/api/ClientReconciliationActExcel/${ClientId}?start=${startDate}&end=${endDate}`;

        this._downloadUrl(url);
    }

    downloadProviderReconciliationActPdf(ProviderId, Start, End) {
        let url = '';
        let selectedCurrency = store.getters.selectedCurrency;
        let startDate = this._getDateDDMMYYYFormat(Start);
        let endDate = this._getDateDDMMYYYFormat(End);
        if (selectedCurrency)
            url = `${ApiHost}/api/ProviderReconciliationActPdf/${ProviderId}?start=${startDate}&end=${endDate}&currencyId=${selectedCurrency.CurrencyId}`;
        else
            url = `${ApiHost}/api/ProviderReconciliationActPdf/${ProviderId}?start=${startDate}&end=${endDate}`;
        this._downloadUrl(url);
    }

    downloadProviderReconciliationItemActExcel(ProviderId, Start, End) {
        let url = '';
        let selectedCurrency = store.getters.selectedCurrency;
        let startDate = this._getDateDDMMYYYFormat(Start);
        let endDate = this._getDateDDMMYYYFormat(End);
        if (selectedCurrency)
            url = `${ApiHost}/api/ProviderReconciliationItemActExcel/${ProviderId}?start=${startDate}&end=${endDate}&currencyId=${selectedCurrency.CurrencyId}`;
        else
            url = `${ApiHost}/api/ProviderReconciliationItemActExcel/${ProviderId}?start=${startDate}&end=${endDate}`;
        this._downloadUrl(url);
    }

    downloadProviderReconciliationActExcel(ProviderId, Start, End) {
        let url = '';
        let selectedCurrency = store.getters.selectedCurrency;
        let startDate = this._getDateDDMMYYYFormat(Start);
        let endDate = this._getDateDDMMYYYFormat(End);
        if (selectedCurrency)
            url = `${ApiHost}/api/ProviderReconciliationActExcel/${ProviderId}?start=${startDate}&end=${endDate}&currencyId=${selectedCurrency.CurrencyId}`;
        else
            url = `${ApiHost}/api/ProviderReconciliationActExcel/${ProviderId}?start=${startDate}&end=${endDate}`;
        this._downloadUrl(url);
    }

    downloadMaterialPurchasePdf(purchase, isPrint) {
        let url = '';
        let selectedCurrency = store.getters.selectedCurrency;
        if (selectedCurrency)
            url = `${ApiHost}/api/MaterialPurchasePdf/${purchase.PurchaseId}?currencyId=${selectedCurrency.CurrencyId}`;
        else
            url = `${ApiHost}/api/MaterialPurchasePdf/${purchase.PurchaseId}`;

        this._downloadUrl(url, isPrint);
    }

    downloadItemBarcode({Barcode, Name, Price, IsPrint, SizeParams, ShowPrice, BarcodeType}) {
        //let selectedCurrency = store.getters.selectedCurrency;
        let url = 'https://barcode.hippo.uz/barcode';
        if (BarcodeType === 'ean13') {
            url = 'https://barcode.hippo.uz/ean13';
        }
        let urlParams = {
            barcode: Barcode,
            name: Name,
            x: SizeParams.x,
            y: SizeParams.y
        }
        if (BarcodeType !== 'ean13') {
            urlParams.copyCount = SizeParams.copyCount;
        }
        if (ShowPrice) {
            urlParams.price = Price;
            urlParams.currency = SizeParams.currencyName;
        }
        return this._downloadUrl(url, IsPrint, urlParams);
    }

    async downloadItemReportToExcel(urlParams) {
        let url = `${ApiHost}/api/ItemPeriodSaleReportExcel`;
        if (urlParams.startDate) {
            urlParams.start = this._getDateDDMMYYYFormat(urlParams.startDate);
            delete urlParams.startDate;
        }
        if (urlParams.endDate) {
            urlParams.end = this._getDateDDMMYYYFormat(urlParams.endDate);
            delete urlParams.endDate;
        }
        await this._downloadUrl(url, false, urlParams);
    }
    async downloadClientReportToExcel(urlParams) {
        let url = `${ApiHost}/api/ClientPeriodReportExcel`;
        if (urlParams.startDate) {
            urlParams.start = this._getDateDDMMYYYFormat(urlParams.startDate);
            delete urlParams.startDate;
        }
        if (urlParams.endDate) {
            urlParams.end = this._getDateDDMMYYYFormat(urlParams.endDate);
            delete urlParams.endDate;
        }
        await this._downloadUrl(url, false, urlParams);
    }

    _downloadUrl(url, isPrint, urlParams) {
        let token = JSON.parse(localStorage.auth);
        let config = {
            responseType: 'blob',
            headers: {
                'Authorization': `Bearer ${token.access_token}`
            }
        }
        if (urlParams)
            config.params = urlParams;
        let promise = axios.get(url, config);

        promise.then(response => {
            const blob = new Blob([response.data]);
            const url = window.URL.createObjectURL(blob);
            if (isPrint) {
                printJs(url);
            } else {
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', decodeURI(response.headers['x-file-name']));
                document.body.appendChild(link);
                link.click();
                link.remove();
                window.URL.revokeObjectURL(url);
            }
        }).catch(error => {
        });
        return promise;
    }

    _getDateDDMMYYYFormat(date) {
        if (date) {
            let day = date.getDate().toString();
            day = day.length === 1 ? `0${day}` : day;

            let month = (date.getMonth() + 1).toString();
            month = month.length === 1 ? `0${month}` : month;

            let year = date.getFullYear().toString();

            return `${day}.${month}.${year}`;
        }
        return "";
    }
}

export default new Downloader()
