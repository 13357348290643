import translation from "../translation/translate";

export const warehouseItemsDatatableFields = [
    {key: '#', label: '#', checked: true},
    {
        key: "name",
        label: translation.translate("frequentlyRepeated", "tovar"),
        checked: true,
        labelKey: 'tovar',
        stickyColumn: true,
        class: 'max-width-200'
    },
    {
        key: "state",
        label: translation.translate("frequentlyRepeated", "ostatok"),
        labelKey: 'ostatok',
        checked: true,
        class: 'text-right'
    },
    {
        key: "price",
        label: translation.translate("frequentlyRepeated", "SenaProdajiTovara"),
        labelKey: 'SenaProdajiTovara',
        checked: true,
        class: 'text-right'
    },
    {
        key: "barcode",
        label: translation.translate("frequentlyRepeated", "kod"),
        labelKey: 'kod',
        checked: true,
    },
    {
        key: "createdAt",
        label: translation.translate("frequentlyRepeated", "DataSozdaniya"),
        labelKey: 'DataSozdaniya',
        checked: true,
    },
];

export const saleDataTableFields = [
    {
        key: '#',
        label: '#',
        checked: true
    },
    {
        key: "client",
        label: translation.translate("frequentlyRepeated", "klient"),
        labelKey: 'klient',
        checked: true,
    },
    {
        key: "isApproved",
        label: translation.translate("frequentlyRepeated", "status"),
        labelKey: 'status',
        checked: true
    },
    {
        key: "clientPayment",
        label: translation.translate("frequentlyRepeated", "StatusOplati"),
        labelKey: 'StatusOplati',
        checked: true,
        haveAccess: true,
    },
    {
        key: "total",
        label: translation.translate("frequentlyRepeated", "ObshayaSummaPrixoda"),
        labelKey: 'ObshayaSummaPrixoda',
        checked: true,
        class: 'text-right'
    },
    {
        key: "totalPaid",
        label: translation.translate("frequentlyRepeated", "oplata"),
        labelKey: 'oplata',
        checked: true,
        haveAccess: true,
        class: 'text-right'
    },
    {
        key: "paymentCashAmount",
        label: translation.translate("frequentlyRepeated", "oplataNalichnimi"),
        labelKey: 'oplataNalichnimi',
        checked: true,
        haveAccess: true,
        class: 'text-right'
    },
    {
        key: "paymentCardAmount",
        label: translation.translate("frequentlyRepeated", "oplataVBankovskiyKarti"),
        labelKey: 'oplataVBankovskiyKarti',
        checked: true,
        haveAccess: true,
        class: 'text-right'
    },
    {
        key: "humoTransferAmount",
        label: translation.translate("frequentlyRepeated", "oplataCherezKartaHumo"),
        labelKey: 'oplataCherezKartaHumo',
        checked: false,
        haveAccess: true,
        class: 'text-right'
    },
    {
        key: "account",
        label: translation.translate("frequentlyRepeated", "prodavets"),
        labelKey: 'prodavets',
        checked: true,
    },
    {
        key: "createdAt",
        label: translation.translate("frequentlyRepeated", "dataProdaja"),
        labelKey: 'dataProdaja',
        checked: true,
    },
    {key: "actions", label: "", class: 'text-right min-width-100', checked: true}
]
