import { render, staticRenderFns } from "./ClientForm.vue?vue&type=template&id=932e74ea&"
import script from "./ClientForm.vue?vue&type=script&lang=js&"
export * from "./ClientForm.vue?vue&type=script&lang=js&"
import style0 from "./ClientForm.vue?vue&type=style&index=0&id=932e74ea&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports