<template>
    <component :is="appendPrepend" v-if="currencies.length > 0">
        <b-dropdown :toggle-class="massAddForm ? 'p-1' : 'px-1'" toggle-class="rounded-end border-0" variant="info">
            <template v-slot:button-content v-if="!massAddForm">
                <span class="d-none d-lg-inline-block">
                    {{
                        PriceCurrencyValue && PriceCurrencyValue.Currency ? PriceCurrencyValue.Currency.Name + ' | ' + PriceCurrencyValue.Rate : translation.translate('frequentlyRepeated', 'Sum')
                    }}
                </span>
                <span class="d-inline-block d-lg-none"><i
                    class="iconsminds-synchronize h5"></i></span>
            </template>
            <template v-slot:button-content v-else>
                <i class="iconsminds-synchronize"></i>
                {{ PriceCurrencyValue && PriceCurrencyValue.Currency ? PriceCurrencyValue.Currency.Name : '' }}
            </template>
            <b-dropdown-item v-if="PriceCurrencyValue && PriceCurrencyValue.Currency"
                             @click="setNullCurrencyToPrice(PriceCurrencyValue)">
                {{translation.translate('frequentlyRepeated', 'Sum')}}
            </b-dropdown-item>
            <b-dropdown-item v-for="currency in currencies"
                             @click="onSelectedCurrencyToPrice(currency, PriceCurrencyValue)"
                             :key="currency.CurrencyId">
                {{ currency.Name }} | {{ currency.Rate }}

                <i class="text-primary simple-icon-check d-inline-block d-lg-none pl-2"
                   v-if="PriceCurrencyValue && (currency.CurrencyId === PriceCurrencyValue.CurrencyId)"></i>
            </b-dropdown-item>
        </b-dropdown>
    </component>
</template>

<script>
import {mapGetters} from "vuex";
import translation from "@/translation/translate";
import math from "../../plugins/math";

export default {
    name: "AppendCurrencyToInput",
    props: {
        PriceCurrencyValue: {
            default: null,
            type: Object
        },
        isAppend: {
            default: false,
            type: Boolean
        },
        massAddForm: {
            default: false,
            type: Boolean
        },
    },
    data() {
        return {
            translation
        }
    },
    computed: {
        ...mapGetters({
            currencies: "getAllCurrencies",
            selectedCurrency: "selectedCurrency",
        }),
        appendPrepend() {
            return this.isAppend ? 'b-input-group-append' : 'b-input-group-prepend'
        }
    },
    methods: {
        setNullCurrencyToPrice(toItem) {
            toItem.Value = toItem.Currency && toItem.Rate ? math.multiply(toItem.Rate, toItem.Value) : toItem.Value;
            toItem.Currency = null;
            toItem.CurrencyId = null;
            toItem.CurrencyValueId = null;
            toItem.Rate = null;
        },
        onSelectedCurrencyToPrice(selectedCurrency, toItem) {
            if (selectedCurrency.Rate && toItem.Value && !toItem.Rate) {
                toItem.Value = Math.round10(toItem.Value / selectedCurrency.Rate, -4);
            }
            toItem.Currency = selectedCurrency;
            toItem.CurrencyId = selectedCurrency.CurrencyId;
            toItem.Rate = selectedCurrency.Rate;
        },
    }
}
</script>

<style scoped>

</style>
