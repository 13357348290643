<template>
    <v-select
        :options="warehouses"
        v-model="selectedWarehouse"
        label="Name"
        class="bg-body bg-opacity-25 rounded w-75"/>
</template>

<script>
import {mapGetters} from "vuex";

export default {
    name: "WarehouseSearchElement",
    computed: {
        ...mapGetters({
            warehouses: 'getAllWarehouses',
            getSelectedWarehouse: 'selectedWarehouse',
        }),
        selectedWarehouse: {
            get() {
                return this.getSelectedWarehouse;
            },
            set(warehouse) {
                localStorage.setItem('selectedWarehouse', JSON.stringify(warehouse));
                this.$store.commit('selectWarehouse', {warehouse: warehouse});
            }
        }
    }
}
</script>

<style scoped>

</style>