<template>
    <div>
        <b-card class="soft-primary my-2">
            <b-row>
                <b-col md="8">
                    <b-input :placeholder='translation.translate("frequentlyRepeated", "Poisk")'
                             v-model="search"
                             autofocus
                             @input="searchDebounce()"/>
                </b-col>
                <b-col md="4" class="ml-auto text-right">
                    <TableLimit :limit="limit" @newLimit="onChangeLimit"/>
                </b-col>
            </b-row>
        </b-card>
        <b-row>
            <b-col cols="12">
                <b-card>
                    <b-overlay :show="isLoadedProducts" spinner-variant="primary">
                        <b-table
                            ref="datatable"
                            hover
                            small
                            sort-icon-left
                            no-sort-reset
                            :sort-by="sortBy"
                            :sort-desc="sortDesc"
                            @sort-changed="sortingChanged"
                            :items="warehouseItems"
                            :fields="fields"
                            :no-footer-sorting="true"
                            show-empty
                            :responsive="true"
                            class="position-relative"
                            :tbody-tr-class="rowClass">
                            <template v-slot:cell(#)="scope">
                                {{ (page - 1) * limit + scope.index + 1 }}
                            </template>
                            <template v-slot:cell(name)="scope">
                                {{ scope.item.Item.Name }}
                            </template>
                            <template v-slot:cell(state)="scope">
                                {{ scope.item.State | decimal }}
                                {{ scope.item.Item.Measurement }}
                            </template>
                            <template v-slot:cell(price)="scope">
                                {{
                                    scope.item.Item.PriceCurrencyValue.Value | division(scope.item.Item.PriceCurrencyValue.Currency || null) | decimal
                                }}
                                {{
                                    scope.item.Item.PriceCurrencyValue.Currency ? scope.item.Item.PriceCurrencyValue.Currency.Name : ''
                                }}
                            </template>
                            <template v-slot:cell(barcode)="scope">
                                {{ scope.item.Item.Barcodes[0] }}
                            </template>
                            <template v-slot:cell(createdAt)="scope">
                                {{ scope.item.CreatedAt | datetime }}
                            </template>
                            <template v-slot:cell(empty)>
                                <h6 class="text-center">{{
                                        translation.translate("frequentlyRepeated", "netDanniyx")
                                    }}</h6>
                            </template>
                        </b-table>
                    </b-overlay>
                    <Pagination :page="page" :length="warehouseItems.length" :limit="limit" @prevPage="onPrev()"
                                @nextPage="onNext()"></Pagination>
                </b-card>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import WarehouseItemService from "../../../services/warehouse/warehouse.item.service";
import {mapGetters} from "vuex";
import translate from "../../../translation/translate";
import TableLimit from "../../../components/TableLimit";
import {debounce} from "underscore";
import {warehouseItemsDatatableFields} from "../../../constants/datatable_fields";
import Pagination from "../../../components/additional/Pagination";

export default {
    name: "AllWarehouseItems",
    components: {Pagination, TableLimit},
    data() {
        return {
            translation: translate,
            page: this.$route.query && this.$route.query.page ? +this.$route.query.page : 1,
            sortBy: this.$route.query && this.$route.query.sortBy ? this.$route.query.sortBy : 'name',
            sortDesc: this.$route.query && this.$route.query.sortDesc === 'true' ? true : false,
            isLoadedProducts: false,
            selectedStatusFilter: {value: 0, text: 'Все'},
            search: this.$route.query && this.$route.query.search ? this.$route.query.search : '',
            sku: this.$route.query && this.$route.query.sku ? this.$route.query.sku : '',
            limit: localStorage.getItem('limit') || '10',
            alert: this.$route.query.alert || null,
            category: null,
            tag: null,

            priceCurrency: this.$route.query && this.$route.query.priceCurrency ? +this.$route.query.priceCurrency : null,
            bulkPriceCurrency: this.$route.query && this.$route.query.bulkPriceCurrency ? +this.$route.query.bulkPriceCurrency : null,
            purchasesPriceCurrency: this.$route.query && this.$route.query.purchasesPriceCurrency ? +this.$route.query.purchasesPriceCurrency : null,

            warehouseItems: [],
            fields: warehouseItemsDatatableFields
        }
    },
    computed: {
        ...mapGetters({
            currencies: "getAllCurrencies",
            selectedCurrency: "selectedCurrency",
            selectedWarehouse: "selectedWarehouse",
        }),
    },
    methods: {
        onPrev() {
            this.page = this.page - 1;
            this.update();
        },
        onNext() {
            this.page = this.page + 1;
            this.update();
        },
        onChangeLimit(selectedLimit) {
            let vm = this;
            vm.limit = selectedLimit;
            this.page = 1;
            vm.update();
        },
        rowClass(data) {
            if (data) {
                if (data.State < data.Item.AlertOn && data.State < 0) {
                    return "table-danger";
                } else if (data.Item.AlertOn > 0 && data.State < data.Item.AlertOn) {
                    return "table-warning"
                }
            }
        },
        sortingChanged(ctx) {
            this.sortBy = ctx.sortBy;
            this.sortDesc = ctx.sortDesc;
            this.update();
        },
        update() {
            this.updateRoute({
                selectedStatusFilter: this.selectedStatusFilter ? this.selectedStatusFilter.value : null,
                search: this.search.trim(),
                sku: this.sku.trim(),
                limit: this.limit,
                category: this.category ? this.category.ItemCategoryId : null,
                tag: this.tag ? this.tag.TagId : null,
                sortBy: this.sortBy ? this.sortBy : null,
                sortDesc: this.sortDesc,
                priceCurrency: this.priceCurrency,
                bulkPriceCurrency: this.bulkPriceCurrency,
                purchasesPriceCurrency: this.purchasesPriceCurrency
            });
        },
        updateRoute({
                        selectedStatusFilter,
                        search,
                        sku,
                        limit,
                        category,
                        tag,
                        sortBy,
                        sortDesc,
                        priceCurrency,
                        bulkPriceCurrency,
                        purchasesPriceCurrency
                    }) {
            let routeQuery = {}
            routeQuery.sortDesc = sortDesc;
            if (selectedStatusFilter) {
                routeQuery.selectedStatusFilter = selectedStatusFilter;
            }
            if (search) {
                routeQuery.search = search;
            }
            if (sku) {
                routeQuery.sku = sku;
            }
            if (limit) {
                routeQuery.limit = limit;
            }
            if (category) {
                routeQuery.category = category;
            }
            if (tag) {
                routeQuery.tag = tag;
            }
            if (sortBy) {
                routeQuery.sortBy = sortBy;
            }
            if (priceCurrency || priceCurrency === 0) {
                routeQuery.priceCurrency = priceCurrency;
            }
            if (bulkPriceCurrency || bulkPriceCurrency === 0) {
                routeQuery.bulkPriceCurrency = bulkPriceCurrency;
            }
            if (purchasesPriceCurrency || purchasesPriceCurrency === 0) {
                routeQuery.purchasesPriceCurrency = purchasesPriceCurrency;
            }
            this.$router.replace({name: this.$route.name, query: routeQuery}).catch(err => {
            });
            this.loadProducts();
        },
        searchDebounce: debounce(function () {
            this.page = 1;
            this.update();
        }, 1000),
        loadProducts() {
            this.isLoadedProducts = true;
            let categoryId = null;
            if (this.$route.query && this.$route.query.category) {
                categoryId = this.$route.query.category;
            }
            WarehouseItemService.getAll({
                warehouseId: this.selectedWarehouse.WarehouseId,
                type: 1,
                query: this.search.trim(),
                sku: this.sku.trim(),
                desc: this.sortDesc,
                sort: this.sortBy ? this.sortBy : '',
                skip: (this.page - 1) * +this.limit,
                limit: this.limit,

                stateeq: this.selectedStatusFilter && this.selectedStatusFilter.value === 3 ? 0 : null,
                statelt: this.selectedStatusFilter && this.selectedStatusFilter.value === 4 ? 0 : null,
                stategt: this.selectedStatusFilter && this.selectedStatusFilter.value === 1 ? 0 : null,
                statelteq: null,
                stategteq: null,
                alert: this.selectedStatusFilter && this.selectedStatusFilter.value === 2 ? true : null,

                categoryId: categoryId,
                tag: this.tag,

                priceCurrency: this.priceCurrency,
                bulkPriceCurrency: this.bulkPriceCurrency,
                purchasesPriceCurrency: this.purchasesPriceCurrency,
            }).then(warehouseItems => {
                this.warehouseItems = warehouseItems;
                this.isLoadedProducts = false;
            });
        },
    },
    mounted() {
        this.loadProducts();
    },
}
</script>

<style scoped>

</style>