<template>
    <div>
        <GlobalEvents
            @keyup="keyUpHandlingInRetailSaleItemsTable"
        />
        <b-modal id="retail-item-remove-modal" hide-header hide-footer ref="RetailItemRemoveModal">
            <h4>{{ translate.translate("frequentlyRepeated", "XotiteUdalit") }}</h4>
            <hr>
            <h6>{{ translate.translate("frequentlyRepeated", "deystviyaOtmenitPotomNelzya") }}</h6>
            <b-button-toolbar class="float-right mt-5">
                <b-button-group class="mx-1">
                    <b-button @click="onCancelRemove" type="button">
                        {{ translate.translate("frequentlyRepeated", "otmenit") }}
                    </b-button>
                </b-button-group>
                <b-button-group class="mx-1">
                    <b-button
                        :disabled="isSubmittedDelete"
                        type="submit"
                        variant="primary"
                        class="shadow-sm"
                        @click="removeItem"
                    >
                        <b-spinner v-if="isSubmittedDelete" small></b-spinner>
                        {{ translate.translate("frequentlyRepeated", "udalit") }}
                    </b-button>
                </b-button-group>
            </b-button-toolbar>
        </b-modal>
        <b-table :responsive="true"
                 :items="Items"
                 hover
                 :fields="fields"
                 class="retail-items-table"
                 ref="RetailItemsTable"
                 :tbody-tr-class="selectedRowClass">
            <template v-slot:cell(#)="scope">
                <small class="fw-bolder">{{ scope.index + 1 }}</small>
            </template>
            <template v-slot:cell(name)="scope">
                {{ scope.item.WarehouseItem.Item.Name | kitcut }}
            </template>
            <template v-slot:cell(price)="scope">
                <div class="d-flex align-items-center" style="width: 50px">
                    {{
                        scope.item.PriceCurrencyValue.Currency ? scope.item.PriceCurrencyValue.Currency.Name : ''
                    }}
                    <IMaskPriceInput v-model="scope.item.PriceCurrencyValue.Value"
                                     ref="editingItemPrice"
                                     @blur="editItem(scope.item)"
                                     style="width: 120px;"
                                     class="border-0"
                                     @keyupEnter="editItem(scope.item)"
                                     @focus="($event)=>{$event.target.select()}"
                    />
                </div>
                <div class="text-danger"
                     v-if="$v.Items.$each[scope.index].PriceCurrencyValue.Value.$error && !$v.Items.$each[scope.index].PriceCurrencyValue.Value.required">
                    {{ translate.translate("frequentlyRepeated", "vvediteSena") }}
                </div>
                <div class="text-danger"
                     v-if="$v.Items.$each[scope.index].PriceCurrencyValue.Value.$error && !$v.Items.$each[scope.index].PriceCurrencyValue.Value.minValue">
                    Не соответствующий значения
                </div>
            </template>
            <template v-slot:cell(quantity)="scope">
                <b-button-group class="w-100" size="sm">
                    <b-button
                        style="max-width: 50px;"
                        class="border p-0 bg-white text-dark"
                        @click="decrement(scope)"
                    >-
                    </b-button>
                    <b-form-input
                        v-model="scope.item.Quantity"
                        style="max-width: 60px;" size="sm"
                        class="border border-start-0 border-end-0 rounded-0 text-center"
                    />
                    <b-button
                        style="max-width: 50px;"
                        class="border p-0 bg-white text-dark"
                        @click="increment(scope)"
                    >+
                    </b-button>
                </b-button-group>
            </template>
            <template v-slot:cell(total)="scope">
                {{ mathHelper(scope.item.PriceCurrencyValue.Value, scope.item.Quantity) }}
                {{
                    scope.item.PriceCurrencyValue.Currency ? scope.item.PriceCurrencyValue.Currency.Name : ''
                }}
            </template>
            <template v-slot:cell(actions)="scope">
                <div style="width: 5px;">
                    <b-icon-trash class="cursor-pointer align-middle" @click="showDeleteItemModal(scope.item)"/>
                </div>
            </template>
            <template #empty="scope">
                <div class="text-center">
                    <span>Отсканируйте штрих-код продукта</span>
                </div>
            </template>
        </b-table>
    </div>
</template>

<script>
import math from "../../../src/plugins/math";
import IMaskPriceInput from "../imask/IMaskPriceInput";
import {decimal, minValue, required} from "vuelidate/lib/validators";
import {validationMixin} from "vuelidate";
import SaleItemService from "../../services/sale/sale.item.service";
import translate from "../../translation/translate";
import GlobalEvents from 'vue-global-events'

export default {
    name: "SaleBasketListComponent",
    components: {IMaskPriceInput, GlobalEvents},
    mixins: [validationMixin],
    props: {
        Sale: {
            default: {},
            type: Object
        },
        Items: {
            default: [],
            type: Array
        }
    },
    data() {
        return {
            translate,
            selectedRow: null,
            clickedNumpad: null,
            timeout: null,
            typingNumbers: '',
            isSubmittedDelete: false
        }
    },
    validations: {
        Items: {
            $each: {
                PriceCurrencyValue: {
                    Value: {
                        required,
                        decimal,
                        minValue: minValue(0),
                    }
                }
            }
        }
    },
    computed: {
        manualModalValue() {
            return this.$parent.isActiveManualAddProduct
        },
        fields() {
            return [
                '#',
                {key: "name", label: "Названия товара", class: 'width-500'},
                {key: "price", label: "Цена"},
                {key: "quantity", label: "Количество"},
                {key: "total", label: "Общая сумма"},
                {key: "actions", label: ""}
            ]
        },
    },
    methods: {
        showDeleteItemModal(item){
            this.selectedRow = item;
            this.$bvModal.show('retail-item-remove-modal');
        },
        onCancelRemove() {
            this.$bvModal.hide('retail-item-remove-modal');
            this.selectedRow = null;
        },
        selectedRowClass(item) {
            if (!item || !this.selectedRow) {
                return '';
            }
            if (item.SaleItemId === this.selectedRow.SaleItemId) {
                return 'table-secondary';
            } else {
                return '';
            }
        },
        keyUpHandlingInRetailSaleItemsTable(event) {
            if (!event) {
                return;
            }
            if (!this.manualModalValue && !this.focusInField) {
                switch (event.code) {
                    case 'ArrowDown':
                        this.onClickArrowDown();
                        break;
                    case 'ArrowUp':
                        this.onClickArrowUp();
                        break;
                    case 'ArrowRight':
                        if (this.selectedRow)
                            this.increment(this.selectedRow);
                        break;
                    case 'ArrowLeft':
                        if (this.selectedRow)
                            this.decrement(this.selectedRow);
                        break;
                    case 'Numpad1':
                    case 'Numpad2':
                    case 'Numpad3':
                    case 'Numpad4':
                    case 'Numpad5':
                    case 'Numpad6':
                    case 'Numpad7':
                    case 'Numpad8':
                    case 'Numpad9':
                    case 'NumpadDecimal':
                    case 'Numpad0':
                        let vm = this;
                        clearTimeout(vm.timeout);
                        vm.typingNumbers = vm.typingNumbers ? vm.typingNumbers + event.key : event.key;
                        vm.timeout = setTimeout(function () {
                            vm.clickedNumpad = vm.typingNumbers;
                            vm.typingNumbers = '';
                        }, 2000);
                        break;
                    case 'NumpadAdd':
                        this.clickedNumpad = this.typingNumbers;
                        if (this.selectedRow) {
                            this.editItem(this.selectedRow, 'quantity');
                        } else {
                            this.selectedRow = null;
                            this.typingNumbers = '';
                        }
                        break;
                    case 'NumpadMultiply':
                        this.clickedNumpad = this.typingNumbers;
                        if (this.selectedRow)
                            this.editItem(this.selectedRow, 'price');
                        else {
                            this.selectedRow = null;
                            this.typingNumbers = '';
                        }
                        break;
                    case 'NumpadDivide':
                        this.clickedNumpad = this.typingNumbers;
                        if (this.selectedRow) this.editItem(this.selectedRow, 'quantityByPrice');
                        break;
                    case 'Delete':
                        if (this.selectedRow)
                            this.$bvModal.show('retail-item-remove-modal');
                        break;
                    case 'Enter':
                        if (this.selectedRow && this.$refs.RetailItemRemoveModal.isShow)
                            this.removeItem();
                        break;
                    case 'Escape':
                        this.selectedRow = null;
                        this.typingNumbers = '';
                }
            }
        },
        onClickArrowUp() {
            if (this.Items.length === 0) return;
            if (!this.selectedRow) {
                this.selectedRow = this.Items[this.Items.length - 1];
            } else {
                let selectedRowIndex = this.Items.findIndex(item => item.SaleItemId === this.selectedRow.SaleItemId);
                if (selectedRowIndex > 0) {
                    this.selectedRow = this.Items[selectedRowIndex - 1];
                } else if (selectedRowIndex === 0) {
                    this.selectedRow = this.Items[this.Items.length - 1];
                }
            }
        },
        onClickArrowDown() {
            if (this.Items.length === 0)
                return;
            if (!this.selectedRow) {
                this.selectedRow = this.Items[0];
            } else {
                let selectedRowIndex = this.Items.findIndex(item => item.SaleItemId === this.selectedRow.SaleItemId);
                if (this.Items[selectedRowIndex + 1])
                    this.selectedRow = this.Items[selectedRowIndex + 1];
                else
                    this.selectedRow = this.Items[0];
            }
        },
        editItem(editingItem, field) {
            let vm = this;
            if ((field === 'quantity' || field === 'quantityByPrice') && vm.getCheckedItemStateMoreThanZero && (editingItem.WarehouseItem.State < +vm.clickedNumpad)) {
                return this.$bvToast.toast(translate.translate("frequentlyRepeated", "NeOstalos"), {
                    title: 'В наличии этот товар не осталось!',
                    autoHideDelay: 500,
                    toaster: 'b-toaster-top-center',
                    variant: 'warning'
                });
            }
            if (field === 'price') {
                editingItem.PriceCurrencyValue.Value = +vm.clickedNumpad;
            } else if (field === 'quantity') {
                editingItem.Quantity = +vm.clickedNumpad;
            } else if (field === 'quantityByPrice') {
                editingItem.Quantity = Math.round10(+vm.clickedNumpad / +editingItem.PriceCurrencyValue.Value, -4);
            }
            SaleItemService.edit(editingItem.SaleItemId, {
                sale: vm.Sale,
                saleItem: editingItem
            }).then((updatedItem) => {
                vm.$emit('reloadSale');
                vm.clickedNumpad = null;
                vm.typingNumbers = '';
            });
        },
        decrement(saleItem) {
            if (saleItem.Quantity <= 1)
                return;
            saleItem.Quantity = saleItem.Quantity - 1;
            this.editItem(saleItem);
        },
        increment(saleItem) {
            saleItem.Quantity = saleItem.Quantity + 1;
            this.editItem(saleItem);
        },
        onEditingItemPrice($event) {
            $event.target.select();
        },
        //remove item
        removeItem() {
            let vm = this;
            vm.isSubmittedDelete = true;
            SaleItemService.delete(vm.selectedRow.SaleItemId).then(() => {
                let index = vm.Items.indexOf(vm.selectedRow);
                if (index > -1) vm.Items.splice(index, 1);
                vm.$bvModal.hide('retail-item-remove-modal');
                vm.isSubmittedDelete = false;
                vm.selectedRow = null;
                vm.$emit('reloadSale');
            });
        },
        mathHelper(item1, item2) {
            return math.multiply(item1, item2)
        },
    }
}
</script>

<style scoped>
.width-500 {
    width: 500px !important;
}
</style>