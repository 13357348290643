<template>
    <b-input-group-append>
        <b-button class="rounded-1 border-0" variant="info" @click="setValueTotal(PaymentType)">
            {{ translation.translate("frequentlyRepeated", "vsyaSumma") }}
        </b-button>
    </b-input-group-append>
</template>

<script>
import {mapGetters} from "vuex";
import translation from '../../translation/translate';

export default {
    name: "WholeAmountComponent",
    props: {
        PaymentType: {
            type: String,
            default: 'PaymentCashCurrencyValue',
        },
        debt: {
            type: Number,
            default: null,
        },
        PaymentPerson: {
            type: Object,
            default: null,
        }
    },
    data() {
        return {
            translation
        }
    },
    computed: {
        ...mapGetters({ selectedCurrency: 'selectedCurrency' })
    },
    methods: {
        setValueTotal(field) {
            let vm = this;
            let debt = vm.debt;
            if (debt > 0 && !vm.selectedCurrency) {
                if (vm.PaymentPerson[field].Currency) {
                    let c = debt / vm.PaymentPerson[field].Currency.Rate;
                    let convertedDebt = Math.round10(c, -4);
                    vm.PaymentPerson[field].Value = vm.PaymentPerson[field].Value > 0 ? +vm.PaymentPerson[field].Value + convertedDebt : convertedDebt;
                } else {
                    vm.PaymentPerson[field].Value = vm.PaymentPerson[field].Value.length > 0 ? +vm.PaymentPerson[field].Value + debt : debt;
                }
            } else if (debt > 0 && vm.selectedCurrency.Rate) {
                if (vm.PaymentPerson[field].Currency) {
                    let roundedDebt = Math.round10(debt, -4);
                    vm.PaymentPerson[field].Value = vm.PaymentPerson[field].Value.length > 0 ? +vm.PaymentPerson[field].Value + roundedDebt : roundedDebt;
                } else {
                    let noCurrency = debt * vm.selectedCurrency.Rate;
                    vm.PaymentPerson[field].Value = Math.round10(noCurrency)
                }
            }
        }
    }
}
</script>

<style scoped>

</style>
