<template>
    <v-select ref="select"
              class="w-100"
              v-model="item"
              @search="onSearch"
              :options="items"
              :clearable="true"
              :filterable="false"
              :select-on-tab="true"
              :push-tags="isAllowAdd"
              :taggable="isAllowAdd"
              :create-option="onCreateOption"
              :filterBy="() => { return true;}"
              @input="onInput"
              :no-drop="noDrop"
              :placeholder='translation.translate("frequentlyRepeated", "VvediteImyaTovar")'
              v-on:search:focus="() => { $emit('focus') }"
              v-on:search:blur="() => { $emit('blur') }"
              :get-option-label="(option) => option.Item.Name"
    >
        <template slot="option" slot-scope="scope">
            <div class="p-0 m-0 h6 d-flex justify-content-between">
                <small>
                    {{ scope.Item.Name }}
                </small>
                <div>
                    <b-badge variant="success" class="mr-2">{{ scope.State | decimal }}</b-badge>
                    <b-badge variant="info">{{
                            scope.Item.PriceCurrencyValue.Value | division(scope.Item.PriceCurrencyValue.Currency || null) | decimal
                        }}
                        {{
                            scope.Item.PriceCurrencyValue.Currency ? scope.Item.PriceCurrencyValue.Currency.Name : ''
                        }}
                    </b-badge>
                </div>
            </div>
        </template>
        <template slot="selected-option" slot-scope="scope">
                <span v-if="scope.Item.Name">
                    {{ scope.Item.Name }}
                </span>
            <b-badge v-if="isShowState" :variant="variantState(scope)" class="ml-2">
                {{ scope.State | decimal }}
            </b-badge>
        </template>
        <template slot="no-options">
            {{ translation.translate("frequentlyRepeated", "neNaydeno") }}
        </template>
    </v-select>
</template>

<script>
import {debounce} from 'underscore';
import translation from '../../translation/translate';
import vSelect from "vue-select";
import {mapGetters} from "vuex";
import WarehouseItemService from "@/services/warehouse/warehouse.item.service";

export default {
    props: ['text', 'value', 'showState', 'showPrice', 'allowAdd', 'noDrop', 'warehouse', 'pageName'],
    name: "WarehouseItemSearchElement",
    components: {
        'v-select': vSelect,
    },
    data() {
        return {
            translation: translation,
            search: "",
            items: [],
            item: null,
            isShowPrice: false,
            isShowState: false,
            isAllowAdd: false,
            itemType: null
        };
    },
    computed: {
        ...mapGetters({
            selectedWarehouse: "selectedWarehouse",
        }),
    },
    methods: {
        addNewItem() {
            this.$refs.ItemForm.reset();
            this.$bvModal.show('ItemFormOnItemSearchElement');
        },
        editItem() {
            this.$refs.ItemForm.reset();
            this.$refs.ItemForm.set(this.item.Item)
            this.$refs.ItemForm.setWarehouseItemId(this.item.WarehouseItemId)
            this.$refs.ItemForm.setLastPurchasePriceCurrencyValue(this.item.LastPurchasePriceCurrencyValue);
            this.$bvModal.show('ItemFormOnItemSearchElement')
        },
        variantState(scope) {
            if (scope.State < 0)
                return 'warning';
            if (scope.Item.AlertOn > scope.State)
                return 'danger';
            if (scope.State > 0)
                return 'success';
        },
        setItemType(value) {
            this.itemType = value;
        },
        itemAdded(item) {
            if (item) {
                this.updateItemData()
            }
            this.$bvModal.hide("ItemFormOnItemSearchElement");
            this.$refs.ItemForm.reset();
        },
        onCreateOption(itemName) {
            if (this.isAllowAdd) {
                this.showAddItemModal();
                this.$refs.ItemForm.setItemName(itemName);
            }
        },
        showAddItemModal() {
            this.$refs.ItemForm.reset();
            this.$bvModal.show("ItemFormOnItemSearchElement");
        },
        onInput(item) {
            this.$emit("input", item);
        },
        onSearch(search, loading) {
            if (search.length > 0)
                this.searchDebounce(loading, search, this);
        },
        searchDebounce: debounce((loading, search, vm) => {
            if (search.length > 0) {
                loading();
                WarehouseItemService.getAll({
                    warehouseId: vm.warehouse ? vm.warehouse.WarehouseId : vm.selectedWarehouse.WarehouseId,
                    type: vm.itemType,
                    query: search,
                    sku: '',
                    desc: false,
                    sort: 'name',
                    skip: 0,
                    limit: 20,
                    stateeq: null,
                    statelt: null,
                    stategt: null,
                    statelteq: null,
                    stategteq: null,
                    alert: null,
                    category: null
                }).then((items) => {
                    // on item found by barcode, set item selected
                    let barcode = null;
                    if (items.length > 0)
                        barcode = items[0].Item.Barcodes.find(barcode => search === barcode);
                    if (items.length === 1 && barcode) {
                        vm.item = items[0];
                        vm.onInput(items[0]);

                        vm.items = [];
                    } else {
                        vm.items = items;
                    }
                    loading();
                });
            }
        }, 800),
        focus() {
            setTimeout(() => {
                this.$refs.select.$refs.search.focus();
            }, 50);
        },
        clear() {
            this.item = null;
            this.items = [];
            this.$refs.select.open = false;
        },
        updateItemData() {
            let vm = this;
            if (vm.item) {
                WarehouseItemService.get(vm.item.WarehouseItemId).then(function (response) {
                    vm.onInput(response)
                });
            }
        }
    },
    created() {
        this.item = this.value;

        this.isShowState = !!this.showState;
        this.isShowPrice = !!this.showPrice;

        this.isAllowAdd = !!this.allowAdd;
    },
    watch: {
        value(v) {
            this.item = v;
        },
        showPrice(v) {
            this.isShowPrice = v;
        },
        item(v) {
            this.$emit("input", v);
        }
    }
}
</script>

<style lang="scss" scoped>
.v-select > > > .vs__dropdown-menu {
    max-height: 350px !important;
}

.v-select > > > .vs__dropdown-menu::-webkit-scrollbar {
    background-color: #f8f9fa;
    border-radius: 9px;
    width: 5px;
    height: 8px;
}

.v-select > > > .vs__dropdown-menu::-webkit-scrollbar-thumb {
    background: rgb(193, 193, 193);
    border-radius: 9px;
    background-clip: content-box;
    cursor: pointer !important;
}

.b-dropdown > > > .dropdown-toggle {
    background: #202259;
    color: #fff;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
}

label {
    margin: 10px 0;
}

.w-15 {
    width: 15% !important;
}

button {
    padding: 7px 20px;
}
</style>
