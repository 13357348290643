import EPosSaleService from "../services/epos/epossale.service";
import translation from '@/translation/translate';
import EPosApiService from "../services/epos/EPosApiService";
import printJS from "print-js";
import {mapGetters} from "vuex";

let EPosMixin = {
    data() {
        return {
            translation: translation,
        }
    },
    computed: {
        ...mapGetters({
            statusEPos: 'getStatusEPos',
            organization: "getOrganization",
        })
    },
    methods: {
        async sendSaleToEPos(SaleId) {
            let vm = this
            if (vm.organization.KkmStatus && vm.statusEPos) {
                let isConfirmed = await vm.$bvModal.msgBoxConfirm(translation.translate("frequentlyRepeated", "ProvestiProdajusSKKM"), {
                    title: translation.translate("frequentlyRepeated", "XotiteProvestiProdajusKKM"),
                    okTitle: translation.translate("frequentlyRepeated", "Podtverjdayu"),
                    cancelTitle: translation.translate("frequentlyRepeated", "NePodtverjdayu"),
                    buttonSize: 'lg'
                }).catch(errHandler => {
                });
                if (isConfirmed) {
                    await EPosSaleService.get(SaleId).then(async res => {
                        await EPosApiService.sendSale(res.data.data).then(async response => {
                            if (response && response.data && !response.data.error) {
                                await EPosSaleService.edit(SaleId, {
                                    info: response.data.info,
                                    paycheck: response.data.paycheck
                                }).then(async () => {
                                    printJS({
                                        printable: response.data.paycheck,
                                        type: "pdf",
                                        base64: true
                                    });
                                }).catch(error => {
                                });
                            } else {
                                this.$bvToast.toast(translation.translate("frequentlyRepeated", "OshibkaPriOtpravlenie"), {
                                    title: response ? response.data.message : translation.translate("frequentlyRepeated", "oshibka"),
                                    autoHideDelay: 1000,
                                    toaster: 'b-toaster-bottom-left',
                                    variant: 'danger'
                                })
                            }
                        }).catch(error => {
                        })
                    }).catch(err => {
                    });
                }
            }
        },
    }
}
export default EPosMixin;
