<template>
    <b-modal
        centered
        size="lg"
        title="Новая продажа"
        id="manual-add-modal"
        hide-header-close
        v-model="isShown"
    >
        <template #modal-header>
            <h5>Новая продажа</h5>
            <b-button-close @click="onCancel"/>
        </template>
        <template #default>
            <b-row>
                <b-col cols="12" lg="12">
                    <label>Название товара</label>
                    <WarehouseItemSearchElement ref="WarehouseItemSearchElement" v-model="NewItem.Item"/>
                    <template v-if="$v.NewItem.Item.$error">
                        <small
                            class="text-danger"
                            v-if="!$v.NewItem.Item.required"
                        >Выберите товар</small>
                    </template>
                </b-col>
                <b-col cols="12" lg="6">
                    <label>Цена</label>
                    <b-input-group>
                        <IMaskPriceInput v-model="NewItem.PriceCurrencyValue.Value"
                                         :class="{ 'is-invalid': $v.NewItem.PriceCurrencyValue.Value.$error }"
                                         @focus="() => { if (!NewItem.PriceCurrencyValue.Value) NewItem.PriceCurrencyValue.Value = ''}"
                                         @blur="() => { if (!NewItem.PriceCurrencyValue.Value ) NewItem.PriceCurrencyValue.Value = 0}"/>
                        <AppendCurrencyToInput ref="AppendCurrencyToInput" :is-append="true"
                                               :price-currency-value="NewItem.PriceCurrencyValue"/>
                        <template v-if="$v.NewItem.PriceCurrencyValue.Value.$error">
                            <small
                                class="text-danger"
                                v-if="!$v.NewItem.PriceCurrencyValue.Value.required"
                            >Введите цена</small>
                        </template>
                    </b-input-group>
                </b-col>
                <b-col cols="12" lg="6">
                    <label>Количество</label>
                    <b-form-input
                        type="number"
                        ref="quantity"
                        v-model="NewItem.Quantity"
                        @keyup.enter="addItem(NewItem)"
                    ></b-form-input>
                    <template v-if="$v.NewItem.Quantity.$error">
                        <small
                            class="text-danger"
                            v-if="!$v.NewItem.Quantity.required"
                        >Введите количество</small>
                    </template>
                </b-col>
                <b-col cols="12" class="my-2" v-if="NewItem.Item && NewItem.Item.StateParts.length > 0">
                    <label>Детали остатки</label>
                    <b-input-group>
                        <v-select :options="NewItem.Item.StateParts" @input="onSelectedStateParts" label="Name"
                                  class="w-100" multiple placeholder="Выберите остаток">
                            <template slot="option" slot-scope="scope">
                                <div class="p-0 m-0">
                                    <span class="mr-2">{{ scope.Name }}</span>
                                    <b-badge variant="success">
                                        {{ scope.State | decimal }}
                                    </b-badge>
                                </div>
                            </template>
                            <template slot="selected-option" slot-scope="scope">
                                <span class="mr-2">{{ scope.Name }}</span>
                                <b-badge>
                                    {{ scope.State | decimal }}
                                </b-badge>
                            </template>
                        </v-select>
                    </b-input-group>
                </b-col>
                <b-col cols="12" xl="12" class="my-2" v-if="NewItem.Item && NewItem.Item.StateParts.length > 0">
                    <template v-if="NewItem.StatePartOperations.length > 0">
                        <b-row>
                            <b-col cols="12" xl="6" v-for="operation in NewItem.StatePartOperations"
                                   :key="operation.Id">
                                <label>{{ operation.Name }} количество</label>
                                <b-input-group>
                                    <b-form-input v-model="operation.Quantity"/>
                                </b-input-group>
                            </b-col>
                        </b-row>
                    </template>
                </b-col>
            </b-row>
        </template>
        <template #modal-footer="{ cancel }">
            <b-button
                pill
                @click="addItem(NewItem)"
                class="border-0"
                variant="info"
                :disabled="isSubmitted"
            >
                <b-spinner v-if="isSubmitted" small></b-spinner>
                Добавить
            </b-button>
            <b-button @click="onCancel" pill variant="outline-secondary">
                Отмена
            </b-button>
        </template>
    </b-modal>
</template>

<script>
import {validationMixin} from "vuelidate";
import WarehouseItemSearchElement from "../searchElements/WarehouseItemSearchElement";
import AppendCurrencyToInput from "../currency/AppendCurrencyToInput";
import IMaskPriceInput from "../imask/IMaskPriceInput";

const {required} = require("vuelidate/lib/validators")

export default {
    name: 'ManualAddItemModal',
    components: {IMaskPriceInput, AppendCurrencyToInput, WarehouseItemSearchElement},
    data() {
        return {
            NewItem: {
                PriceCurrencyValue: {
                    Currency: null,
                    CurrencyId: null,
                    CurrencyValueId: null,
                    Rate: null,
                    Value: 0
                },
                Quantity: '',
                Item: null,
                DiscountCurrencyValue: {
                    Currency: null,
                    CurrencyId: null,
                    CurrencyValueId: null,
                    Rate: null,
                    Value: 0
                },
                StatePartOperations: [{
                    Id: '',
                    Name: '',
                    Quantity: 0
                }]
            },
            isSubmitted: false,
            isShown: false
        }
    },
    validations: {
        NewItem: {
            Item: {
                required
            },
            PriceCurrencyValue: {
                Value: {
                    required
                }
            },
            Quantity: {
                required
            }
        }
    },
    mixins: [validationMixin],
    methods: {
        onCancel() {
            this.resetSelectedItem();
            this.$bvModal.hide('manual-add-modal');
        },
        onSelectedStateParts(selectedStatePart) {
            this.NewItem.StatePartOperations = selectedStatePart.map(part => {
                return {
                    Id: part.Id,
                    Name: part.Name,
                    Quantity: part.State >= this.NewItem.Quantity ? this.NewItem.Quantity : part.State
                }
            });
        },
        clear(){
            this.resetSelectedItem()
            this.$v.NewItem.$reset();
            this.focus();
            this.isSubmitted = false;
        },
        resetSelectedItem() {
            this.NewItem = {
                Item: null,
                PriceCurrencyValue: {
                    Currency: null,
                    CurrencyId: null,
                    CurrencyValueId: null,
                    Rate: null,
                    Value: 0
                },
                Quantity: null,
                DiscountCurrencyValue: {
                    Currency: null,
                    CurrencyId: null,
                    CurrencyValueId: null,
                    Rate: null,
                    Value: 0
                },
                StatePartOperations: [{
                    Id: '',
                    Name: '',
                    Quantity: 0
                }]
            }
        },
        addItem(NewItem) {
            let vm = this;
            vm.$v.$touch();
            if (!vm.$v.NewItem.$invalid) {
                this.isSubmitted = true;
                this.$emit('addProduct', NewItem);
            }
        },
        focus() {
            this.$refs.WarehouseItemSearchElement.focus();
        },
    },
    watch: {
        'NewItem.Item'(newV) {
            let vm = this;
            if (newV) {
                vm.NewItem.PriceCurrencyValue = newV.Item.PriceCurrencyValue
                setTimeout(() => {
                    this.$refs.quantity.focus();
                }, 200)
            } else {
                vm.resetSelectedItem()
            }
        }
    }
}

</script>

<style scoped>
.v-select >>> .vs__dropdown-menu {
    max-height: 350px !important;
}

.v-select >>> .vs__dropdown-menu::-webkit-scrollbar {
    background-color: #f8f9fa;
    border-radius: 9px;
    width: 5px;
    height: 8px;
}

.v-select >>> .vs__dropdown-menu::-webkit-scrollbar-thumb {
    background: rgb(193, 193, 193);
    border-radius: 9px;
    background-clip: content-box;
    cursor: pointer !important;
}

.b-dropdown >>> .dropdown-toggle {
    background: #202259;
    color: #fff;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
}

label {
    margin: 10px 0;
}

.w-15 {
    width: 15% !important;
}

button {
    padding: 7px 20px;
}
</style>

